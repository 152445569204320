export const warehousedata = [
  {
    id: "1",
    warehouse: "Legendary",
    contactPerson: "Steven",
    phone: "+1 45445 4454",
    totalProducts: "04",
    stock: "55",
    qty: "600",
    createdOn: "04 Aug 2023",
    status: "Active",
    img: "assets/img/users/user-08.jpg",
    action: "",
  },
  {
    id: "2",
    warehouse: "Determined",
    contactPerson: "Gravely",
    phone: "+1 63728 3467",
    totalProducts: "04",
    stock: "60",
    qty: "300",
    createdOn: "18 Sep 2023",
    status: "Active",
    img: "assets/img/users/user-04.jpg",
    action: "",
  },
  {
    id: "3",
    warehouse: "Sincere",
    contactPerson: "Kevin",
    phone: "+1 95628 1036",
    totalProducts: "04",
    stock: "26",
    qty: "250",
    createdOn: "05 Oct 2023",
    status: "Active",
    img: "assets/img/users/user-09.jpg",
    action: "",
  },
  {
    id: "4",
    warehouse: "Pretty",
    contactPerson: "Grillo",
    phone: "+1 65730 1603",
    totalProducts: "04",
    stock: "47",
    qty: "400",
    createdOn: "21 Nov 2023",
    status: "Active",
    img: "assets/img/users/user-10.jpg",
    action: "",
  },
];
