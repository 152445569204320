/* eslint-disable react/prop-types */
/* eslint-disable no-unreachable */
// import { Calendar, PlusCircle } from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  addInvoice,
  getBusiness,
  getCustomer,
  getProduct,
  updateInvoice,
} from "../../../service/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { PlusCircle } from "react-feather";

const validationSchema = Yup.object().shape({
  businessId: Yup.string().required("Business ID is required"),
  businessCustomerId: Yup.string().required("Business Customer ID is required"),
  gstType: Yup.string().required("GST Type is required"),
  igst: Yup.number().required("IGST is required").min(0, "Minimum IGST is 0"),
  sgst: Yup.number().required("SGST is required").min(0, "Minimum SGST is 0"),
  cgst: Yup.number().required("CGST is required").min(0, "Minimum CGST is 0"),
  cashType: Yup.string().required("Cash type is required"),
  remainingAmount: Yup.number().required("Remaining Amount is required"),
});

const AddInvoice = ({ edit, selectedData, getData }) => {
  const [businessData, setBusinessData] = useState([]);
  const [product, setProduct] = useState([]);
  const [customer, setCustomer] = useState([]);

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      businessId: "",
      businessCustomerId: "",
      products: [],
      gstType: "",
      igst: 0,
      sgst: 0,
      cgst: 0,
      discountPr: 0,
      discountAmount: 0,
      otherCharges: [],
      totalAmount: "",
      finalAmount: "",
      remainingAmount: 0,
      cashType: "",
      invoiceStatus: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      if (!edit) {
        addInvoice(values)
          .then((res) => {
            console.log("res--->", res);
            if (res?.status) {
              toast.success(res?.message);
              getData();
              formik.resetForm();
              document
                ?.getElementById("add-invoice")
                ?.classList?.remove("show", "d-block", "modal-open");
              document
                ?.getElementsByClassName("modal-backdrop")[0]
                ?.classList?.remove("modal-backdrop");
            } else {
              toast.error(res?.message);
            }
          })
          .catch((e) => {
            toast.error(e?.message);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        let body = {
          invoiceStatus: values?.invoiceStatus,
          remainingAmount: values?.remainingAmount,
        };
        updateInvoice(selectedData?._id, body)
          .then((res) => {
            console.log("res--->", res);
            if (res?.status) {
              toast.success(res?.message);
              getData();
              formik.resetForm();
              document
                ?.getElementById("add-invoice")
                ?.classList?.remove("show", "d-block", "modal-open");
              document
                ?.getElementsByClassName("modal-backdrop")[0]
                ?.classList?.remove("modal-backdrop");
            } else {
              toast.error(res?.message);
            }
          })
          .catch((e) => {
            toast.error(e?.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  useEffect(() => {
    if (selectedData) {
      formik.setFieldValue("businessId", selectedData?.business?._id);
      formik.setFieldValue(
        "businessCustomerId",
        selectedData?.businessCustomer?._id
      );
      let product = [];
      selectedData?.products?.map((obj) => {
        product.push({
          product: obj?.product?._id,
          quantity: obj?.quantity,
          price: obj?.price,
        });
      });
      formik?.setFieldValue("products", product);
      let other = [];
      selectedData?.otherCharges?.map((obj) => {
        other.push({ title: obj?.title, amount: obj?.amount });
      });
      formik?.setFieldValue("otherCharges", other);
      formik?.setFieldValue("gstType", selectedData?.gstType);
      formik?.setFieldValue("igst", selectedData?.igst);
      formik?.setFieldValue("sgst", selectedData?.sgst);
      formik?.setFieldValue("cgst", selectedData?.cgst);
      formik?.setFieldValue("discountPr", selectedData?.discountPr);
      formik?.setFieldValue("discountAmount", selectedData?.discountAmount);
      if (selectedData?.discountPr > 0) {
        setDiscount("PERCENTAGE");
      }
      if (selectedData?.discountAmount > 0) {
        setDiscount("FLAT");
      }
    } else {
      formik.resetForm();
    }
  }, [selectedData]);

  const getDataBusiness = () => {
    getBusiness()
      .then((res) => {
        // console.log("%c Line:42 🍅 res", "color:#ea7e5c", res);
        if (res?.status) {
          if (res?.data?.length > 0) {
            setBusinessData(res?.data);
          } else {
            setBusinessData([]);
          }
        }
      })
      .catch((e) => {
        console.log("%c Line:45 🍬 e", "color:#4fff4B", e);
      });
  };

  const getDataProduct = () => {
    getProduct({ page: 1, sizePerPage: 1000 })
      .then((res) => {
        // console.log("%c Line:42 🍅 res", "color:#ea7e5c", res);
        if (res?.status) {
          setProduct(res?.data?.docs);
        } else {
          setProduct([]);
        }
      })
      .catch((e) => {
        console.log("%c Line:45 🍬 e", "color:#4fff4B", e);
      });
  };

  const getCustomerData = () => {
    getCustomer({
      page: 1,
      sizePerPage: 1000,
    })
      .then((res) => {
        console.log("%c Line:42 🍅 res", "color:#ea7e5c", res);
        if (res?.status) {
          setCustomer(res?.data?.docs);
        }
      })
      .catch((e) => {
        console.log("%c Line:45 🍬 e", "color:#4fff4B", e);
      });
  };

  useEffect(() => {
    getDataBusiness();
    getDataProduct();
    getCustomerData();
  }, []);
  // Product array
  const [price, setPrice] = useState("");
  const [priceErr, setPriceErr] = useState(false);
  const [qty, setQty] = useState("");
  const [qtyErr, setQtyErr] = useState(false);
  const [productId, setProductId] = useState("");
  const [productIdErr, setProductIdErr] = useState(false);

  useEffect(() => {
    if (productId) {
      let price =
        product && product?.find((obj) => obj?._id === productId)?.price;
      setPrice(price);
    }
  }, [productId]);

  const handleProduct = () => {
    setPriceErr(!price || price <= 0);
    setQtyErr(!qty || qty <= 0);
    setProductIdErr(!productId);
    if (productId && qty > 0 && price > 0) {
      let productNumber =
        product && product?.find((obj) => obj?._id === productId)?.typeNumber;
      let body = {
        product: productId,
        quantity: qty,
        price: price,
        productNumber: productNumber,
      };
      const update = [...formik.values.products, body];
      formik.setFieldValue("products", update);
      setPrice("");
      setQty("");
      setProductId("");
    }
  };
  const handleDelete = (e) => {
    let array = formik?.values?.products?.filter((obj, i) => i !== e);
    formik.setFieldValue("products", array);
  };
  console.log("%c Line:179 🍿 formik", "color:#4fff4B", formik?.values);

  // Other Charges array
  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState(false);
  const [amount, setAmount] = useState("");
  const [amountErr, setAmountErr] = useState(false);

  const handleOtherCharges = () => {
    setTitleErr(!title);
    setAmountErr(!amount || amount <= 0);

    if (amount > 0 && title) {
      let body = {
        title: title,
        amount: amount,
      };
      const update = [...formik.values.otherCharges, body];
      formik.setFieldValue("otherCharges", update);
      setTitle("");
      setAmount("");
    }
  };
  const handleDeleteOther = (e) => {
    let array = formik?.values?.otherCharges?.filter((obj, i) => i !== e);
    formik.setFieldValue("otherCharges", array);
  };

  const gst = [
    { value: "NO-GST", label: "NO-GST" },
    { value: "IGST", label: "IGST" },
    { value: "SGST-CGST", label: "SGST-CGST" },
  ];
  const cashType = [
    { value: "DEBIT", label: "DEBIT" },
    { value: "CASH", label: "CASH" },
  ];
  const invoiceStatus = [
    { value: "PAID", label: "PAID" },
    { value: "UNPAID", label: "UNPAID" },
    { value: "DRAFT", label: "DRAFT" },
    { value: "PARTIALPAID", label: "PARTIALPAID" },
    { value: "CANCELLED", label: "CANCELLED" },
  ];
  const discountList = [
    { value: "PERCENTAGE", label: "PERCENTAGE" },
    { value: "FLAT", label: "FLAT" },
  ];
  const [discount, setDiscount] = useState("");

  // useEffect(() => {
  //   if (discount === "PERCENTAGE") {
  //     formik.setFieldValue("discountAmount", 0);
  //     formik.setFieldValue("discountPr", "");
  //   } else if (discount === "FLAT") {
  //     formik.setFieldValue("discountAmount", "");
  //     formik.setFieldValue("discountPr", 0);
  //   }
  // }, [discount]);

  // useEffect(() => {
  //   if (formik?.values?.gstType === "NO-GST") {
  //     formik.setFieldValue("cgst", 0);
  //     formik.setFieldValue("sgst", 0);
  //     formik.setFieldValue("igst", 0);
  //   } else if (formik?.values?.gstType === "IGST") {
  //     formik.setFieldValue("cgst", 0);
  //     formik.setFieldValue("sgst", 0);
  //     formik.setFieldValue("igst", "");
  //   } else if (formik?.values?.gstType === "SGST-CGST") {
  //     formik.setFieldValue("cgst", "");
  //     formik.setFieldValue("sgst", "");
  //     formik.setFieldValue("igst", 0);
  //   }
  // }, [formik?.values?.gstType]);

  useEffect(() => {
    if (formik?.values?.products && formik?.values?.products?.length > 0) {
      let total =
        formik?.values?.products &&
        formik?.values?.products?.length > 0 &&
        formik?.values?.products?.reduce(
          (acc, crr) => acc + +crr?.quantity * +crr?.price,
          0
        );
      total.toFixed(2);
      let final = total;
      if (
        formik?.values?.otherCharges &&
        formik?.values?.otherCharges?.length > 0
      ) {
        total =
          total +
          formik?.values?.otherCharges?.reduce(
            (acc, crr) => acc + +crr?.amount,
            0
          );
        final =
          final +
          formik?.values?.otherCharges?.reduce(
            (acc, crr) => acc + +crr?.amount,
            0
          );
        total.toFixed(2);
        final.toFixed(2);
      }

      if (formik?.values?.gstType && formik?.values?.gstType === "IGST") {
        total = total + (formik?.values?.igst * total) / 100;
        total.toFixed(2);
        final = final + (formik?.values?.igst * final) / 100;
        final.toFixed(2);
      }
      if (formik?.values?.gstType && formik?.values?.gstType === "NO-GST") {
        total =
          total +
          (formik?.values?.cgst * total) / 100 +
          (formik?.values?.sgst * total) / 100 +
          (formik?.values?.igst * total) / 100;
        total.toFixed(2);
        final =
          final +
          (formik?.values?.cgst * final) / 100 +
          (formik?.values?.sgst * final) / 100 +
          (formik?.values?.igst * final) / 100;
        final.toFixed(2);
      }
      if (formik?.values?.gstType && formik?.values?.gstType === "SGST-CGST") {
        total =
          total +
          (formik?.values?.cgst * total) / 100 +
          (formik?.values?.sgst * total) / 100;
        total.toFixed(2);
        final =
          final +
          (formik?.values?.cgst * final) / 100 +
          (formik?.values?.sgst * final) / 100;
        final.toFixed(2);
      }
      if (formik?.values?.discountPr && formik?.values?.discountPr > 0) {
        final = total - (formik?.values?.discountPr * total) / 100;
        final.toFixed(2);
        // formik.setFieldValue("finalAmount", final);
      }
      if (
        formik?.values?.discountAmount &&
        formik?.values?.discountAmount > 0
      ) {
        final = total - formik?.values?.discountAmount;
        final.toFixed(2);
        // formik.setFieldValue("finalAmount", final);
      }
      formik.setFieldValue("totalAmount", total);
      formik.setFieldValue("finalAmount", final);
      if (formik?.values?.cashType === "DEBIT") {
        formik.setFieldValue("remainingAmount", final);
      }
      if (
        // formik?.values?.cashType === "CASH" &&
        formik?.values?.invoiceStatus === "UNPAID"
      ) {
        formik.setFieldValue("remainingAmount", final);
      }
      if (
        // formik?.values?.cashType === "CASH" &&
        formik?.values?.invoiceStatus === "PAID" ||
        formik?.values?.invoiceStatus === "DRAFT" ||
        formik?.values?.invoiceStatus === "CANCELLED"
      ) {
        formik.setFieldValue("remainingAmount", 0);
      }
      // if (
      //   // formik?.values?.cashType === "CASH" &&
      //   formik?.values?.invoiceStatus === "PARTIALPAID"
      // ) {
      //   formik.setFieldValue("remainingAmount", "");
      // }
    }
  }, [
    formik?.values?.products,
    formik?.values?.discountAmount,
    formik?.values?.discountPr,
    formik?.values?.otherCharges,
    formik?.values?.igst,
    formik?.values?.sgst,
    formik?.values?.cgst,
    formik?.values?.invoiceStatus,
    formik?.values?.cashType,
  ]);

  const productName = (e) => {
    if (product && product?.length > 0) {
      let arr = product?.find((obj) => obj?._id === e)?.name;
      return arr;
    }
  };

  useEffect(() => {
    if (selectedData) {
      formik.setFieldValue("businessId", selectedData?.business?._id);
      formik.setFieldValue(
        "businessCustomerId",
        selectedData?.businessCustomer?._id
      );
      let product = [];
      selectedData?.products?.map((obj) => {
        product.push({
          product: obj?.product?._id,
          quantity: obj?.quantity,
          price: obj?.price,
        });
      });
      formik?.setFieldValue("products", product);
      let other = [];
      selectedData?.otherCharges?.map((obj) => {
        other.push({ title: obj?.title, amount: obj?.amount });
      });
      formik?.setFieldValue("otherCharges", other);
      formik?.setFieldValue("gstType", selectedData?.gstType);
      formik?.setFieldValue("igst", selectedData?.igst);
      formik?.setFieldValue("sgst", selectedData?.sgst);
      formik?.setFieldValue("cgst", selectedData?.cgst);
      formik?.setFieldValue("discountPr", selectedData?.discountPr);
      formik?.setFieldValue("discountAmount", selectedData?.discountAmount);
      if (selectedData?.discountPr > 0) {
        setDiscount("PERCENTAGE");
      }
      if (selectedData?.discountAmount > 0) {
        setDiscount("FLAT");
      }
      formik?.setFieldValue("cashType", selectedData?.cashType);
      formik?.setFieldValue("invoiceStatus", selectedData?.invoiceStatus);
      formik?.setFieldValue("finalAmount", selectedData?.finalAmount);
      formik?.setFieldValue("totalAmount", selectedData?.totalAmount);
      formik?.setFieldValue("remainingAmount", selectedData?.remainingAmount);
    } else {
      formik.resetForm();
      setDiscount("");
    }
  }, [selectedData]);
  return (
    <div>
      {/* Add Purchase */}
      <form onSubmit={formik.handleSubmit}>
        <div className="modal fade" id="add-invoice">
          <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>{edit ? "Update" : "Add"} Invoice</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                          <label>Business Name</label>
                          <div className="row">
                            <div className="col-lg-12 col-sm-12 col-12">
                              <select
                                className={
                                  formik.errors.businessId &&
                                  formik.touched.businessId
                                    ? " form-control is-invalid"
                                    : "form-control"
                                }
                                disabled={edit}
                                aria-label="Default select example"
                                name="businessId"
                                id="businessId"
                                value={formik?.values?.businessId}
                                onChange={(e) => formik.handleChange(e)}
                              >
                                <option value="" disabled>
                                  Select Business
                                </option>
                                {businessData && businessData?.length > 0 ? (
                                  businessData?.map((obj, i) => {
                                    return (
                                      <option key={i} value={obj?._id}>
                                        {obj?.name}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option disabled>
                                    Please add your cutomer details.
                                  </option>
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                          <label>Customer Name</label>
                          <div className="row">
                            <div className="col-lg-12 col-sm-12 col-12">
                              <select
                                className={
                                  formik.errors.businessCustomerId &&
                                  formik.touched.businessCustomerId
                                    ? " form-control is-invalid"
                                    : "form-control"
                                }
                                disabled={edit}
                                aria-label="Default select example"
                                name="businessCustomerId"
                                id="businessCustomerId"
                                value={formik?.values?.businessCustomerId}
                                onChange={(e) => formik.handleChange(e)}
                              >
                                <option value="" disabled>
                                  Select Customer
                                </option>
                                {customer && customer?.length > 0 ? (
                                  customer?.map((obj, i) => {
                                    return (
                                      <option key={i} value={obj?._id}>
                                        {obj?.name}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option disabled>
                                    Please add your cutomer details.
                                  </option>
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                          <label>Product Name</label>
                          <div className="row">
                            <div className="col-lg-12 col-sm-12 col-12">
                              <select
                                className={
                                  productIdErr
                                    ? " form-control is-invalid"
                                    : "form-control"
                                }
                                disabled={edit}
                                style={{ width: "100%" }}
                                aria-label="Default select example"
                                name="businessCustomerId"
                                id="businessCustomerId"
                                value={productId}
                                onChange={(e) => {
                                  setProductIdErr(false);
                                  setProductId(e.target.value);
                                }}
                              >
                                <option value="" disabled>
                                  Select Product
                                </option>
                                {product && product?.length > 0 ? (
                                  product?.map((obj, i) => {
                                    return (
                                      <option key={i} value={obj?._id}>
                                        {obj?.name}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option disabled>
                                    Please add your cutomer details.
                                  </option>
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Quantity</label>
                          <input
                            type="number"
                            className={
                              qtyErr
                                ? " form-control is-invalid"
                                : "form-control"
                            }
                            disabled={edit}
                            style={
                              qtyErr
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            value={qty}
                            onChange={(e) => {
                              setQtyErr(false);
                              setQty(e.target.value);
                            }}
                          />
                        </div>
                      </div>{" "}
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Price</label>
                          <div className="row">
                            <div className="col-lg-10 col-sm-10 col-10">
                              <input
                                type="number"
                                className={
                                  priceErr
                                    ? " form-control is-invalid"
                                    : "form-control"
                                }
                                disabled={edit}
                                style={
                                  priceErr
                                    ? { borderColor: "#dc3545" }
                                    : { borderColor: "#e9edf6" }
                                }
                                value={price}
                                readOnly
                                // onChange={(e) => {
                                //   setPriceErr(false);
                                //   setPrice(e.target.value);
                                // }}
                              />
                            </div>
                            <div className="col-lg-2 col-sm-2 col-2 ps-0">
                              <div className="add-icon tab input-blocks">
                                <Link onClick={handleProduct} disabled={edit}>
                                  <PlusCircle className="feather-plus-circles" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="row">
                      {formik?.values?.products &&
                        formik?.values?.products?.length > 0 && (
                          <div className="col-lg-12">
                            <div className="modal-body-table">
                              <div className="table-responsive">
                                <table className="table  datanew">
                                  <thead>
                                    <tr>
                                      <th>Product</th>
                                      <th>Qty</th>
                                      <th>Price</th>
                                      {/* <th>Total</th> */}
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {formik?.values?.products?.map((obj, i) => {
                                      return (
                                        <tr key={i}>
                                          <td className="p-2">
                                            {productName(obj?.product)}
                                          </td>
                                          <td className="p-2">
                                            {obj?.quantity}
                                          </td>
                                          <td className="p-2">{obj?.price}</td>
                                          {/* <td className="p-2">
                                            {parseFloat(obj?.price) *
                                              parseFloat(obj?.qty)}
                                          </td> */}

                                          <td
                                            className="confirm-text mt-2"
                                            onClick={() =>
                                              !edit && handleDelete(i)
                                            }
                                            align="right"
                                          >
                                            <i
                                              data-feather="trash-2"
                                              className="feather-trash-2 p-2"
                                              style={{
                                                cursor: "pointer",
                                                color: "#ff0000",
                                              }}
                                            ></i>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>GST Type</label>
                          <select
                            className={
                              formik.errors.gstType && formik.touched.gstType
                                ? " form-control is-invalid"
                                : "form-control"
                            }
                            disabled={edit}
                            style={{ width: "100%" }}
                            aria-label="Default select example"
                            name="gstType"
                            id="gstType"
                            value={formik.values?.gstType}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                          >
                            <option value="" disabled>
                              Select GST Type
                            </option>
                            {gst &&
                              gst?.length > 0 &&
                              gst?.map((obj, i) => {
                                return (
                                  <option key={i} value={obj?.value}>
                                    {obj?.label}
                                  </option>
                                );
                              })}
                          </select>{" "}
                        </div>
                      </div>
                      {formik?.values?.gstType === "IGST" && (
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="input-blocks">
                            <label>IGST</label>
                            <input
                              type="number"
                              disabled={edit}
                              className={
                                formik.errors.igst && formik.touched.igst
                                  ? " form-control is-invalid"
                                  : "form-control"
                              }
                              name="igst"
                              id="igst"
                              value={formik?.values?.igst}
                              onChange={(e) => formik.handleChange(e)}
                            />
                          </div>
                        </div>
                      )}
                      {formik?.values?.gstType === "SGST-CGST" && (
                        <>
                          {" "}
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="input-blocks">
                              <label>SGST</label>
                              <input
                                type="number"
                                disabled={edit}
                                className={
                                  formik.errors.sgst && formik.touched.sgst
                                    ? " form-control is-invalid"
                                    : "form-control"
                                }
                                name="sgst"
                                id="sgst"
                                value={formik?.values?.sgst}
                                onChange={(e) => formik.handleChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="input-blocks">
                              <label>CGST</label>
                              <input
                                type="number"
                                disabled={edit}
                                className={
                                  formik.errors.cgst && formik.touched.cgst
                                    ? " form-control is-invalid"
                                    : "form-control"
                                }
                                name="cgst"
                                id="cgst"
                                value={formik?.values?.cgst}
                                onChange={(e) => formik.handleChange(e)}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Discount Type</label>
                          <select
                            className={"form-control"}
                            style={{ width: "100%" }}
                            aria-label="Default select example"
                            name="gstType"
                            id="gstType"
                            value={discount}
                            disabled={edit}
                            onChange={(e) => {
                              setDiscount(e?.target?.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Discount Type
                            </option>
                            {discountList &&
                              discountList?.length > 0 &&
                              discountList?.map((obj, i) => {
                                return (
                                  <option key={i} value={obj?.value}>
                                    {obj?.label}
                                  </option>
                                );
                              })}
                          </select>{" "}
                        </div>
                      </div>
                      {discount === "PERCENTAGE" && (
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="input-blocks">
                            <label>Percentage</label>
                            <input
                              type="number"
                              disabled={edit}
                              className={
                                formik.errors.discountPr &&
                                formik.touched.discountPr
                                  ? " form-control is-invalid"
                                  : "form-control"
                              }
                              name="discountPr"
                              id="discountPr"
                              value={formik?.values?.discountPr}
                              onChange={(e) => formik.handleChange(e)}
                            />
                          </div>
                        </div>
                      )}
                      {discount === "FLAT" && (
                        <>
                          {" "}
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="input-blocks">
                              <label>Amount</label>
                              <input
                                type="number"
                                disabled={edit}
                                className={
                                  formik.errors.discountAmount &&
                                  formik.touched.discountAmount
                                    ? " form-control is-invalid"
                                    : "form-control"
                                }
                                name="discountAmount"
                                id="discountAmount"
                                value={formik?.values?.discountAmount}
                                onChange={(e) => formik.handleChange(e)}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Other Charges</label>
                          <input
                            type="text"
                            className={
                              titleErr
                                ? " form-control is-invalid"
                                : "form-control"
                            }
                            disabled={edit}
                            style={
                              titleErr
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            value={title}
                            onChange={(e) => {
                              setTitleErr(false);
                              setTitle(e.target.value);
                            }}
                          />
                        </div>
                      </div>{" "}
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Amount</label>
                          <div className="row">
                            <div className="col-lg-10 col-sm-10 col-10">
                              <input
                                type="number"
                                className={
                                  amountErr
                                    ? " form-control is-invalid"
                                    : "form-control"
                                }
                                disabled={edit}
                                style={
                                  amountErr
                                    ? { borderColor: "#dc3545" }
                                    : { borderColor: "#e9edf6" }
                                }
                                value={amount}
                                onChange={(e) => {
                                  setAmountErr(false);
                                  setAmount(e.target.value);
                                }}
                              />
                            </div>
                            <div className="col-lg-2 col-sm-2 col-2 ps-0">
                              <div className="add-icon tab input-blocks">
                                <Link
                                  onClick={handleOtherCharges}
                                  disabled={edit}
                                >
                                  <PlusCircle className="feather-plus-circles" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="row">
                      {formik?.values?.otherCharges &&
                        formik?.values?.otherCharges?.length > 0 && (
                          <div className="col-lg-12">
                            <div className="modal-body-table">
                              <div className="table-responsive">
                                <table className="table  datanew">
                                  <thead>
                                    <tr>
                                      <th>Title</th>
                                      <th>Amount</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {formik?.values?.otherCharges?.map(
                                      (obj, i) => {
                                        return (
                                          <tr key={i}>
                                            <td className="p-2">
                                              {obj?.title}
                                            </td>
                                            <td className="p-2">
                                              {obj?.amount}
                                            </td>

                                            <td
                                              className="confirm-text mt-2"
                                              onClick={() =>
                                                !edit && handleDeleteOther(i)
                                              }
                                              align="right"
                                            >
                                              <i
                                                data-feather="trash-2"
                                                className="feather-trash-2 p-2"
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#ff0000",
                                                }}
                                              ></i>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Cash Type</label>
                          <select
                            className={
                              formik.errors.cashType && formik.touched.cashType
                                ? " form-control is-invalid"
                                : "form-control"
                            }
                            disabled={edit}
                            style={{ width: "100%" }}
                            aria-label="Default select example"
                            name="cashType"
                            id="cashType"
                            value={formik?.values?.cashType}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "cashType",
                                e?.target?.value
                              );
                            }}
                          >
                            <option value="" disabled>
                              Select Cash Type
                            </option>
                            {cashType &&
                              cashType?.length > 0 &&
                              cashType?.map((obj, i) => {
                                return (
                                  <option key={i} value={obj?.value}>
                                    {obj?.label}
                                  </option>
                                );
                              })}
                          </select>{" "}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Invoice Status</label>
                          <select
                            className={
                              formik.errors.invoiceStatus &&
                              formik.touched.invoiceStatus
                                ? " form-control is-invalid"
                                : "form-control"
                            }
                            style={{ width: "100%" }}
                            aria-label="Default select example"
                            name="invoiceStatus"
                            id="invoiceStatus"
                            value={formik?.values?.invoiceStatus}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "invoiceStatus",
                                e?.target?.value
                              );
                            }}
                          >
                            <option value="" disabled>
                              Select Invoice Type
                            </option>
                            {invoiceStatus &&
                              invoiceStatus?.length > 0 &&
                              invoiceStatus?.map((obj, i) => {
                                return (
                                  <option key={i} value={obj?.value}>
                                    {obj?.label}
                                  </option>
                                );
                              })}
                          </select>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Total Amount</label>
                          <input
                            type="number"
                            className={"form-control"}
                            name="totalAmount"
                            id="totalAmount"
                            value={formik?.values?.totalAmount}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Final Amount</label>
                          <input
                            type="number"
                            className={"form-control"}
                            name="finalAmount"
                            id="finalAmount"
                            value={formik?.values?.finalAmount}
                            readOnly
                          />
                        </div>
                      </div>
                      {formik?.values?.invoiceStatus === "PARTIALPAID" && (
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="input-blocks">
                            <label>Amount Due</label>
                            <input
                              type="number"
                              className={
                                formik.errors.remainingAmount &&
                                formik.touched.remainingAmount
                                  ? " form-control is-invalid"
                                  : "form-control"
                              }
                              name="remainingAmount"
                              id="remainingAmount"
                              value={formik?.values?.remainingAmount}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "remainingAmount",
                                  +e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          disabled={loading}
                          className="btn btn-submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* /Add Purchase */}
    </div>
  );
};

export default AddInvoice;
