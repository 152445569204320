import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { addBusiness, getBusiness } from "../../../service/api";
import { useDropzone } from "react-dropzone";
import { set_business_list } from "../../redux/action";
import { useDispatch } from "react-redux";
// import { Button } from "react-bootstrap";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  addressLine1: yup.string().required("Address is required."),
  addressLine2: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  pinCode: yup.string().required("PIN Code is required."),
  mobile: yup.number().required("Mobile is required."),
});
const AddCategoryList = () => {
  const dispatch = useDispatch();
  const [preview, setPreview] = useState(null);
  const [previewSign, setPreviewSign] = useState(null);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      title: "",
      logo: "",
      mobile: "",
      gstNumber: "",
      sign: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pinCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("name", values?.name);
      values?.title && formdata.append("title", values?.title);
      values?.logo && formdata.append("logo", values?.logo);
      formdata.append("mobile", values?.mobile);
      values?.gstNumber && formdata.append("gstNumber", values?.gstNumber);
      values?.sign && formdata.append("sign", values?.sign);
      let address = {
        addressLine1: values?.addressLine1,
        addressLine2: values?.addressLine2,
        state: values?.state,
        city: values?.city,
        pinCode: values?.pinCode,
      };

      // if (values?.addressLine1) {
      //   address.addressLine1 = values?.addressLine1;
      // }
      // if (values?.addressLine2) {
      //   address.addressLine2 = values?.addressLine2;
      // }
      // if (values?.state) {
      //   address.state = values?.state;
      // }
      // if (values?.city) {
      //   address.city = values?.city;
      // }
      // if (values?.pinCode) {
      //   address.pinCode = values?.pinCode;
      // }
      // Object.values(address).some((value) => value !== "") &&
      formdata.append("address", JSON.stringify(address));
      addBusiness(formdata)
        .then((res) => {
          console.log("res--->", res);
          if (res?.status) {
            toast.success(res?.message);
            getBusiness()
              .then((res) => {
                console.log("%c Line:42 🍅 res", "color:#ea7e5c", res);
                if (res?.status) {
                  dispatch(set_business_list(res?.data));
                }
              })
              .catch((e) => {
                console.log("%c Line:45 🍬 e", "color:#4fff4B", e);
              });
            formik.resetForm();
            document
              ?.getElementById("add-category")
              ?.classList?.remove("show", "d-block", "modal-open");
            document
              ?.getElementsByClassName("modal-backdrop")[0]
              ?.classList?.remove("modal-backdrop");
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          toast.error(e?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      formik.setFieldValue("logo", file);
      setPreview(URL.createObjectURL(file));
    }
  };
  const onDropSign = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      formik.setFieldValue("sign", file);
      setPreviewSign(URL.createObjectURL(file));
    }
  };

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
    useDropzone({
      onDrop: onDrop,
      accept: "image/*",
      multiple: false,
    });
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      onDrop: onDropSign,
      accept: "image/*",
      multiple: false,
    });

  console.log("%c Line:105 🥔 formik", "color:#42b983", formik);
  return (
    <div>
      {/* Add Category */}
      <div className="modal fade" id="add-category">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <form className="form-details" onSubmit={formik.handleSubmit}>
                <div className="content">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Create</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div
                    className="modal-body custom-modal-body"
                    style={{
                      height: "80vh",
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                    }}
                  >
                    <div
                      className={`${
                        formik.errors.name && formik.touched.name
                          ? "mb-0"
                          : "mb-3"
                      }`}
                    >
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.name &&
                          formik.touched.name &&
                          "is-invalid"
                        }`}
                        style={
                          formik.errors.name && formik.touched.name
                            ? { borderColor: "#dc3545" }
                            : { borderColor: "#e9edf6" }
                        }
                        name="name"
                        id="name"
                        value={formik?.values?.name}
                        onChange={(e) => formik.handleChange(e)}
                      />
                    </div>
                    {formik.errors.name && formik.touched.name && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {formik?.errors?.name}
                      </div>
                    )}
                    <div className="mb-3">
                      <label className="form-label">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        // style={
                        //   formik.errors.title && formik.touched.title
                        //     ? { borderColor: "red" }
                        //     : { borderColor: "#e9edf6" }
                        // }
                        name="title"
                        id="title"
                        value={formik?.values?.title}
                        onChange={(e) => formik.handleChange(e)}
                      />
                    </div>

                    <div
                      className={`${
                        formik.errors.name && formik.touched.name
                          ? "mb-0"
                          : "mb-3"
                      }`}
                    >
                      <label className="form-label">Mobile</label>
                      <input
                        type="number"
                        className={`form-control ${
                          formik.errors.mobile &&
                          formik.touched.mobile &&
                          "is-invalid"
                        }`}
                        style={
                          formik.errors.mobile && formik.touched.mobile
                            ? { borderColor: "#dc3545" }
                            : { borderColor: "#e9edf6" }
                        }
                        name="mobile"
                        id="mobile"
                        value={formik?.values?.mobile}
                        onChange={(e) => formik.handleChange(e)}
                      />
                    </div>
                    {formik.errors.mobile && formik.touched.mobile && (
                      <div
                        className={`invalid-feedback`}
                        style={{ display: "block" }}
                      >
                        {formik?.errors?.mobile}
                      </div>
                    )}
                    <div className="mb-3">
                      <label className="form-label">Logo</label>
                      {/* <input
                        type="file"
                        className="form-control"
                        // style={
                        //   formik.errors.mobile && formik.touched.mobile
                        //     ? { borderColor: "red" }
                        //     : { borderColor: "#e9edf6" }
                        // }
                        name="logo"
                        id="logo"
                        // value={formik?.values?.mobile}
                        onChange={(e) => formik.handleChange(e.target.files[0])}
                      /> */}
                      <div
                        {...getRootProps1()}
                        style={{
                          border: "1px solid #e9edf6",
                          borderRadius: "0.35rem",
                          padding: "0.5rem 0.85rem",
                          textAlign: "center",
                        }}
                      >
                        <input {...getInputProps1()} />
                        {preview ? (
                          <img
                            src={preview}
                            alt="Preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                        ) : (
                          <p>
                            Drag & drop an image here, or click to select one
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">GST Number</label>
                      <input
                        type="text"
                        className="form-control"
                        // style={
                        //   formik.errors.gstNumber && formik.touched.gstNumber
                        //     ? { borderColor: "red" }
                        //     : { borderColor: "#e9edf6" }
                        // }
                        name="gstNumber"
                        id="gstNumber"
                        value={formik?.values?.gstNumber}
                        onChange={(e) => formik.handleChange(e)}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Sign</label>
                      {/* <input
                        type="text"
                        className="form-control"
                        // style={
                        //   formik.errors.gstNumber && formik.touched.gstNumber
                        //     ? { borderColor: "red" }
                        //     : { borderColor: "#e9edf6" }
                        // }
                        name="sign"
                        id="sign"
                        value={formik?.values?.sign}
                        onChange={(e) => formik.handleChange(e)}
                      /> */}
                      <div
                        {...getRootProps2()}
                        style={{
                          border: "1px solid #e9edf6",
                          borderRadius: "0.35rem",
                          padding: "0.5rem 0.85rem",
                          textAlign: "center",
                        }}
                      >
                        <input {...getInputProps2()} />
                        {previewSign ? (
                          <img
                            src={previewSign}
                            alt="Preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                        ) : (
                          <p>
                            Drag & drop an image here, or click to select one
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      className={`${
                        formik.errors.addressLine1 &&
                        formik.touched.addressLine1
                          ? "mb-0"
                          : "mb-3"
                      }`}
                    >
                      <label className="form-label">Address</label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.addressLine1 &&
                          formik.touched.addressLine1 &&
                          "is-invalid"
                        }`}
                        style={
                          formik.errors.addressLine1 &&
                          formik.touched.addressLine1
                            ? { borderColor: "#dc3545" }
                            : { borderColor: "#e9edf6" }
                        }
                        name="addressLine1"
                        id="addressLine1"
                        value={formik?.values?.addressLine1}
                        onChange={(e) => formik.handleChange(e)}
                      />
                    </div>
                    {formik.errors.addressLine1 &&
                      formik.touched.addressLine1 && (
                        <div
                          className={`invalid-feedback`}
                          style={{ display: "block" }}
                        >
                          {formik?.errors?.addressLine1}
                        </div>
                      )}
                    <div
                      className={`${
                        formik.errors.addressLine2 &&
                        formik.touched.addressLine2
                          ? "mb-0"
                          : "mb-3"
                      }`}
                    >
                      <label className="form-label">Street </label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.addressLine2 &&
                          formik.touched.addressLine2 &&
                          "is-invalid"
                        }`}
                        style={
                          formik.errors.addressLine2 &&
                          formik.touched.addressLine2
                            ? { borderColor: "#dc3545" }
                            : { borderColor: "#e9edf6" }
                        }
                        name="addressLine2"
                        id="addressLine2"
                        value={formik?.values?.addressLine2}
                        onChange={(e) => formik.handleChange(e)}
                      />
                    </div>
                    {formik.errors.addressLine2 &&
                      formik.touched.addressLine2 && (
                        <div
                          className={`invalid-feedback`}
                          style={{ display: "block" }}
                        >
                          {formik?.errors?.addressLine2}
                        </div>
                      )}
                    <div
                      className={`${
                        formik.errors.city && formik.touched.city
                          ? "mb-0"
                          : "mb-3"
                      }`}
                    >
                      <label className="form-label">City </label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.city &&
                          formik.touched.city &&
                          "is-invalid"
                        }`}
                        style={
                          formik.errors.city && formik.touched.city
                            ? { borderColor: "#dc3545" }
                            : { borderColor: "#e9edf6" }
                        }
                        name="city"
                        id="city"
                        value={formik?.values?.city}
                        onChange={(e) => formik.handleChange(e)}
                      />
                    </div>
                    {formik.errors.city && formik.touched.city && (
                      <div
                        className={`invalid-feedback`}
                        style={{ display: "block" }}
                      >
                        {formik?.errors?.city}
                      </div>
                    )}
                    <div
                      className={`${
                        formik.errors.state && formik.touched.state
                          ? "mb-0"
                          : "mb-3"
                      }`}
                    >
                      <label className="form-label">State </label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.state &&
                          formik.touched.state &&
                          "is-invalid"
                        }`}
                        style={
                          formik.errors.state && formik.touched.state
                            ? { borderColor: "#dc3545" }
                            : { borderColor: "#e9edf6" }
                        }
                        name="state"
                        id="state"
                        value={formik?.values?.state}
                        onChange={(e) => formik.handleChange(e)}
                      />
                    </div>
                    {formik.errors.state && formik.touched.state && (
                      <div
                        className={`invalid-feedback`}
                        style={{ display: "block" }}
                      >
                        {formik?.errors?.state}
                      </div>
                    )}
                    <div
                      className={`${
                        formik.errors.pinCode && formik.touched.pinCode
                          ? "mb-0"
                          : "mb-3"
                      }`}
                    >
                      <label className="form-label">Pin Code </label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.pinCode &&
                          formik.touched.pinCode &&
                          "is-invalid"
                        }`}
                        style={
                          formik.errors.pinCode && formik.touched.pinCode
                            ? { borderColor: "#dc3545" }
                            : { borderColor: "#e9edf6" }
                        }
                        name="pinCode"
                        id="pinCode"
                        value={formik?.values?.pinCode}
                        onChange={(e) => formik.handleChange(e)}
                      />
                    </div>
                    {formik.errors.pinCode && formik.touched.pinCode && (
                      <div
                        className={`invalid-feedback`}
                        style={{ display: "block" }}
                      >
                        {formik?.errors?.pinCode}
                      </div>
                    )}
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        disabled={loading}
                        className="btn btn-submit"
                        type="submit"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </div>
  );
};

export default AddCategoryList;
