export const purchaseslist = [
  {
    id: "1",
    supplierName: "Apex Computers",
    reference: "PT001",
    date: "19 Jan 2023",
    status: "Received",
    grandTotal: "$550",
    paid: "$550",
    due: "$0.00",
    createdBy: "Paid",
  },
  {
    id: "2",
    supplierName: "Beats Headphones",
    reference: "PT002",
    date: "27 Jan 2023",
    status: "Received",
    grandTotal: "$370",
    paid: "$370",
    due: "$0.00",
    createdBy: "Paid",
  },
  {
    id: "3",
    supplierName: "Dazzle Shoes",
    reference: "PT003",
    date: "08 Feb 2023",
    status: "Received",
    grandTotal: "$400",
    paid: "$400",
    due: "$200",
    createdBy: "Partial",
  },
  {
    id: "4",
    supplierName: "Best Accessories",
    reference: "PT004",
    date: "16 Feb 2023",
    status: "Pending",
    grandTotal: "$560",
    paid: "$0.00",
    due: "$560",
    createdBy: "Unpaid",
  },
  {
    id: "5",
    supplierName: "A-Z Store",
    reference: "PT005",
    date: "12 Mar 2023",
    status: "Pending",
    grandTotal: "$240",
    paid: "$0.00",
    due: "$240",
    createdBy: "Unpaid",
  },
  {
    id: "6",
    supplierName: "Hatimi Hardwares",
    reference: "PT006",
    date: "24 Mar 2023",
    status: "Received",
    grandTotal: "$170",
    paid: "$170",
    due: "$0.00",
    createdBy: "Paid",
  },
  {
    id: "7",
    supplierName: "Aesthetic Bags",
    reference: "PT007",
    date: "06 Apr 2023",
    status: "Pending",
    grandTotal: "$230",
    paid: "$0.00",
    due: "$230",
    createdBy: "Unpaid",
  },
  {
    id: "8",
    supplierName: "Alpha Mobiles",
    reference: "PT008",
    date: "14 Apr 2023",
    status: "Received",
    grandTotal: "$300",
    paid: "$150",
    due: "$300",
    createdBy: "Partial",
  },
  {
    id: "9",
    supplierName: "Sigma Chairs",
    reference: "PT009",
    date: "02 May 2023",
    status: "Pending",
    grandTotal: "$620",
    paid: "$0.00",
    due: "$620",
    createdBy: "Unpaid",
  },
  {
    id: "10",
    supplierName: "Zenith Bags",
    reference: "PT010",
    date: "23 May 2023",
    status: "Received",
    grandTotal: "$200",
    paid: "$200",
    due: "$0.00",
    createdBy: "Paid",
  },
];
