import React from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className="nav-header-section">
      <img
        src="assets/img/logo/logo.png"
        alt="img"
        style={{ height: "65px", width: "200px" }}
      />
      {/* <div className="nav-tab">
        <p>Home</p>
        <p>FAQ</p>
      </div> */}
      <button onClick={() => navigate("/signin")}>SignUp</button>
    </div>
  );
};

export default Navbar;
