/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { addBusiness, getBusiness, updateBusiness } from "../../service/api";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  addressLine1: yup.string().required("Address is required."),
  addressLine2: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  pinCode: yup.string().required("PIN Code is required."),
  mobile: yup.number().required("Mobile is required."),
});
const Business = () => {
  const [imagePreview, setImagePreview] = useState();
  const [id, setId] = useState();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const handleImage = (e) => {
    if (e) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e);
    }
  };
  const getData = () => {
    getBusiness()
      .then((res) => {
        console.log("%c Line:42 🍅 res", "color:#ea7e5c", res);
        if (res?.status) {
          if (res?.data && res?.data?.length > 0) {
            handleEdit(res?.data[0]);
          }
        }
      })
      .catch((e) => {
        console.log("%c Line:45 🍬 e", "color:#4fff4B", e);
      });
  };

  const handleEdit = (e) => {
    console.log("%c Line:50 🍢 e", "color:#465975", e);
    setEdit(true);
    setId(e?._id);
    formik.setFieldValue("name", e?.name);
    formik.setFieldValue("title", e?.title);
    formik.setFieldValue("mobile", e?.mobile);
    formik.setFieldValue("logo", e?.logo);
    setImagePreview(e?.logo);
    formik.setFieldValue("gstNumber", e?.gstNumber);
    formik.setFieldValue("sign", e?.sign);
    formik.setFieldValue("addressLine1", e?.address?.addressLine1);
    formik.setFieldValue("addressLine2", e?.address?.addressLine2);
    formik.setFieldValue("city", e?.address?.city);
    formik.setFieldValue("pinCode", e?.address?.pinCode);
    formik.setFieldValue("state", e?.address?.state);
  };
  useEffect(() => {
    getData();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      title: "",
      logo: "",
      mobile: "",
      gstNumber: "",
      sign: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pinCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      if (!edit) {
        const formdata = new FormData();
        formdata.append("name", values?.name);
        values?.title && formdata.append("title", values?.title);
        values?.logo && formdata.append("logo", values?.logo);
        formdata.append("mobile", values?.mobile);
        values?.gstNumber && formdata.append("gstNumber", values?.gstNumber);
        values?.sign && formdata.append("sign", values?.sign);
        let address = {
          addressLine1: values?.addressLine1,
          addressLine2: values?.addressLine2,
          state: values?.state,
          city: values?.city,
          pinCode: values?.pinCode,
        };
        formdata.append("address", JSON.stringify(address));
        addBusiness(formdata)
          .then((res) => {
            console.log("res--->", res);
            if (res?.status) {
              toast.success(res?.message);
              formik.resetForm();
              handleEdit(res?.data);
            } else {
              toast.error(res?.message);
            }
          })
          .catch((e) => {
            toast.error(e?.message);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        const formdata = new FormData();
        // formdata.append("name", values?.name);
        values?.title && formdata.append("title", values?.title);
        values?.logo && formdata.append("logo", values?.logo);
        formdata.append("mobile", values?.mobile);
        values?.gstNumber && formdata.append("gstNumber", values?.gstNumber);
        values?.sign && formdata.append("sign", values?.sign);
        let address = {
          addressLine1: values?.addressLine1,
          addressLine2: values?.addressLine2,
          state: values?.state,
          city: values?.city,
          pinCode: values?.pinCode,
        };
        formdata.append("address", JSON.stringify(address));
        updateBusiness(id, formdata)
          .then((res) => {
            console.log("res--->", res);
            if (res?.status) {
              toast.success(res?.message);
              formik.resetForm();
              handleEdit(res?.data);
            } else {
              toast.error(res?.message);
            }
          })
          .catch((e) => {
            toast.error(e?.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  console.log("%c Line:140 🥛 formik", "color:#ea7e5c", formik);
  return (
    <div className="page-wrapper">
      <form className="form-details" onSubmit={formik.handleSubmit}>
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Business</h4>
              <h6>User Business</h6>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="profile-set">
                <div className="profile-head"></div>
                <div className="profile-top">
                  <div className="profile-content">
                    <div className="profile-contentimg">
                      {imagePreview ? (
                        <img src={imagePreview} alt="img" id="blah" />
                      ) : (
                        <ImageWithBasePath
                          src={"assets/img/customer/customer5.jpg"}
                          alt="img"
                          id="blah"
                        />
                      )}
                      <div className="profileupload">
                        <input
                          type="file"
                          id="imgInp"
                          onChange={(e) => {
                            formik.setFieldValue("logo", e.target.files[0]);
                            handleImage(e.target.files[0]);
                          }}
                        />
                        <Link to="#">
                          <ImageWithBasePath
                            src="assets/img/icons/edit-set.svg"
                            alt="img"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-contentname">
                      <h2>{formik.values.name}</h2>
                      <h4>Updates Your Business Details.</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.name &&
                        formik.touched.name &&
                        "is-invalid"
                      }`}
                      style={
                        formik.errors.name && formik.touched.name
                          ? { borderColor: "#dc3545" }
                          : { borderColor: "#e9edf6" }
                      }
                      name="name"
                      id="name"
                      value={formik?.values?.name}
                      readOnly={edit}
                      onChange={(e) => formik.handleChange(e)}
                    />
                    {formik.errors.name && formik.touched.name && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {formik?.errors?.name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      // style={
                      //   formik.errors.title && formik.touched.title
                      //     ? { borderColor: "red" }
                      //     : { borderColor: "#e9edf6" }
                      // }
                      name="title"
                      id="title"
                      value={formik?.values?.title}
                      onChange={(e) => formik.handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">Mobile</label>
                    <input
                      type="number"
                      className={`form-control ${
                        formik.errors.mobile &&
                        formik.touched.mobile &&
                        "is-invalid"
                      }`}
                      style={
                        formik.errors.mobile && formik.touched.mobile
                          ? { borderColor: "#dc3545" }
                          : { borderColor: "#e9edf6" }
                      }
                      name="mobile"
                      id="mobile"
                      value={formik?.values?.mobile}
                      onChange={(e) => formik.handleChange(e)}
                    />
                    {formik.errors.mobile && formik.touched.mobile && (
                      <div
                        className={`invalid-feedback`}
                        style={{ display: "block" }}
                      >
                        {formik?.errors?.mobile}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">GST Number</label>
                    <input
                      type="text"
                      className="form-control"
                      // style={
                      //   formik.errors.gstNumber && formik.touched.gstNumber
                      //     ? { borderColor: "red" }
                      //     : { borderColor: "#e9edf6" }
                      // }
                      name="gstNumber"
                      id="gstNumber"
                      value={formik?.values?.gstNumber}
                      onChange={(e) => formik.handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.addressLine1 &&
                        formik.touched.addressLine1 &&
                        "is-invalid"
                      }`}
                      style={
                        formik.errors.addressLine1 &&
                        formik.touched.addressLine1
                          ? { borderColor: "#dc3545" }
                          : { borderColor: "#e9edf6" }
                      }
                      name="addressLine1"
                      id="addressLine1"
                      value={formik?.values?.addressLine1}
                      onChange={(e) => formik.handleChange(e)}
                    />
                    {formik.errors.addressLine1 &&
                      formik.touched.addressLine1 && (
                        <div
                          className={`invalid-feedback`}
                          style={{ display: "block" }}
                        >
                          {formik?.errors?.addressLine1}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">Street</label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.addressLine2 &&
                        formik.touched.addressLine2 &&
                        "is-invalid"
                      }`}
                      style={
                        formik.errors.addressLine2 &&
                        formik.touched.addressLine2
                          ? { borderColor: "#dc3545" }
                          : { borderColor: "#e9edf6" }
                      }
                      name="addressLine2"
                      id="addressLine2"
                      value={formik?.values?.addressLine2}
                      onChange={(e) => formik.handleChange(e)}
                    />

                    {formik.errors.addressLine2 &&
                      formik.touched.addressLine2 && (
                        <div
                          className={`invalid-feedback`}
                          style={{ display: "block" }}
                        >
                          {formik?.errors?.addressLine2}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.city &&
                        formik.touched.city &&
                        "is-invalid"
                      }`}
                      style={
                        formik.errors.city && formik.touched.city
                          ? { borderColor: "#dc3545" }
                          : { borderColor: "#e9edf6" }
                      }
                      name="city"
                      id="city"
                      value={formik?.values?.city}
                      onChange={(e) => formik.handleChange(e)}
                    />

                    {formik.errors.city && formik.touched.city && (
                      <div
                        className={`invalid-feedback`}
                        style={{ display: "block" }}
                      >
                        {formik?.errors?.city}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">State</label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.state &&
                        formik.touched.state &&
                        "is-invalid"
                      }`}
                      style={
                        formik.errors.state && formik.touched.state
                          ? { borderColor: "#dc3545" }
                          : { borderColor: "#e9edf6" }
                      }
                      name="state"
                      id="state"
                      value={formik?.values?.state}
                      onChange={(e) => formik.handleChange(e)}
                    />

                    {formik.errors.state && formik.touched.state && (
                      <div
                        className={`invalid-feedback`}
                        style={{ display: "block" }}
                      >
                        {formik?.errors?.state}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">Pin Code</label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.errors.pinCode &&
                        formik.touched.pinCode &&
                        "is-invalid"
                      }`}
                      style={
                        formik.errors.pinCode && formik.touched.pinCode
                          ? { borderColor: "#dc3545" }
                          : { borderColor: "#e9edf6" }
                      }
                      name="pinCode"
                      id="pinCode"
                      value={formik?.values?.pinCode}
                      onChange={(e) => formik.handleChange(e)}
                    />

                    {formik.errors.pinCode && formik.touched.pinCode && (
                      <div
                        className={`invalid-feedback`}
                        style={{ display: "block" }}
                      >
                        {formik?.errors?.pinCode}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <button
                    // onClick={handleData}
                    type="submit"
                    disabled={loading}
                    className="btn btn-submit me-2"
                  >
                    {edit ? "Update" : "Submit"}
                  </button>
                  <Link to="#" className="btn btn-cancel">
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </form>
    </div>
  );
};

export default Business;
