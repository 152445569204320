import React from "react";
import ReadMoreReact from "read-more-react";
import "./homepage.css";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Navbar />
      {/* <div className="page-wrapper"> */}
      <div className="header-section">
        <div>
          <h1>
            Billing Made Easy With Billtick
            <br />
          </h1>
          <div>
            <div>
              <p>
                Take your business to the next level with Billtick! Streamline
                your billing, inventory, and accounting processes with our
                cutting-edge software. Join over 10 million small and
                medium-sized enterprises across India who rely on Billtick for
                efficient business management.
              </p>
            </div>
          </div>
          <button onClick={() => navigate("/signin")}>SignUp now!</button>
        </div>
        <img
          decoding="async"
          src="https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/new-vyapar-hero.webp"
          alt="Billtick invoice, GST Billing software"
        />
      </div>
      <div className="feature">
        <div className="tab">
          <img
            src="https://vyaparapp.in/v/z/wp-content/uploads/2023/05/happy_customer-1.webp"
            alt=""
          />
          <h4>1 Crore +</h4>
          <p>Happy Customers</p>
        </div>
        <div className="tab">
          <img
            src="https://vyaparapp.in/v/z/wp-content/uploads/2023/05/phone_icon-1.webp"
            alt=""
          />
          <h4>FREE</h4>
          <p>Android Mobile App</p>
        </div>
        <div className="tab">
          <img
            src="https://vyaparapp.in/v/z/wp-content/uploads/2023/05/gplay_store-2.webp"
            alt=""
          />
          <h4>Rated 4.7/5</h4>
          <p>On Google Play Store</p>
        </div>
        <div className="tab">
          <img
            src="https://vyaparapp.in/v/z/wp-content/uploads/2023/05/multi_device-1.webp"
            alt=""
          />
          <h4>Multi-Device</h4>
          <p>Use together on Mobile/Desktop</p>
        </div>
        <div className="tab">
          <img
            src="https://vyaparapp.in/v/z/wp-content/uploads/2023/05/multi_user-1.webp"
            alt=""
          />
          <h4>Multi-User</h4>
          <p>User Management Feature</p>
        </div>
      </div>
      <div>
        <div className="detail">
          <img
            src="https://vyaparapp.in/v/z/wp-content/uploads/2024/05/make_gst_bills_and_share_with_your_customers.webp"
            alt=""
          />
          <div>
            <h1>Generate GST Bills in a Click and Share Instantly Online!</h1>
            <ReadMoreReact
              text={`Billtick billing software empowers businesses to establish a professional brand identity with advanced GST billing features. Designed to comply with India’s Goods and Services Tax (GST) laws, Billtick simplifies the invoicing process, making accounting error-free and ensuring the security of your business data. With its user-friendly interface, Billtick allows you to create GST-compliant invoices in just a few steps, using customizable invoice formats.

Billtick is available both online and offline, making it easy for small and medium-sized enterprises (SMEs) to adhere to the best accounting practices. The app seamlessly integrates with your bookkeeping process, offering a variety of professional billing solutions. Invoices can be generated quickly and shared with customers through WhatsApp, email, or printed in multiple formats, including A4, A5, and 2- or 3-inch variations. Unlike other accounting software, Billtick enables invoice creation within minutes, without requiring extensive training.

With over 10 customizable GST invoice formats, including designs inspired by Tally accounting software, Billtick allows you to create professional, unique invoices. The app is available for free on Android with essential accounting features, while premium features can be accessed via an annual subscription. A dedicated Windows version offers a 7-day free trial, allowing businesses to experience its full suite of tools before committing to a subscription.

Billtick’s easy-to-use invoicing software helps businesses streamline their billing process, ensuring GST compliance and smooth business management. It’s the ultimate solution for SMEs looking to elevate their operations with professional, hassle-free accounting.`}
              min={180}
              ideal={180}
              max={200}
              readMoreText="Read more"
            />
          </div>
        </div>
        <div className="detail reverse">
          <div>
            <h1>
              Track, Manage, and Optimize Your Inventory with Ease!{" "}
              <small
                style={{
                  background: "red",
                  padding: 6,
                  fontSize: 7,
                  borderRadius: 36,
                  color: "#fff",
                }}
              >
                Upcoming
              </small>
            </h1>
            <ReadMoreReact
              text={`Billtick offers a powerful inventory management solution with advanced features designed to enhance your business performance. With tools like business reports and inventory tracking, Billtick enables you to monitor sales, manage stock effectively, and streamline operations. By keeping a detailed record of your business data, the app helps you develop strategies to optimize inventory space and improve profitability.

Billtick’s inventory tracking system allows you to record and identify products by batch number, expiry date, manufacturing date, and more, ensuring that stock levels are accurately maintained and available when needed. This comprehensive tracking system also helps detect and prevent theft, while offering real-time inventory updates.

Through the Billtick app, you can monitor live inventory status and set alerts to reorder stock when necessary. You can also track the location of packed orders using delivery challans, ensuring timely deliveries and improved customer satisfaction.

The app automates many manual processes, saving time and reducing errors in accounting. Inventory reports can be exported to Excel for easy analysis, and GST-compliant reports can be generated to help you strategize for higher profits and lower expenses. Billtick’s user-friendly interface makes it easy for employees to manage inventory, minimizing the risk of misplaced items and ensuring smooth day-to-day operations.

Billtick simplifies the entire inventory management process, allowing you to focus on growing your business with confidence and efficiency.`}
              min={180}
              ideal={180}
              max={200}
              readMoreText="Read more"
            />
          </div>
          <img
            src="https://vyaparapp.in/v/z/wp-content/uploads/2024/05/check_inventory_instantly.webp"
            alt=""
          />
        </div>
        <div className="detail">
          <img
            src="https://vyaparapp.in/v/z/wp-content/uploads/2024/05/remind_to_recover_payments.webp"
            alt=""
          />
          <div>
            <h1>
              Never Miss a Payment—Send Timely Reminders and Recover Dues Fast!{" "}
              <small
                style={{
                  background: "red",
                  padding: 6,
                  fontSize: 7,
                  borderRadius: 36,
                  color: "#fff",
                }}
              >
                Upcoming
              </small>
            </h1>
            <ReadMoreReact
              text={`Billtick’s accounting and billing software helps SMEs maintain a healthy cash flow by ensuring timely payments. With its advanced dashboard, Billtick tracks all outstanding payments, making it easy to monitor due amounts. The app’s payment reminder feature allows you to send automated alerts to customers via WhatsApp and email, reminding them of their outstanding balances and due dates.

These reminders help prevent missed payments and keep cash flowing smoothly, minimizing delays. To further enhance customer convenience, Billtick offers a variety of payment options, including cash, credit and debit cards, e-wallets, NEFT, RTGS, UPI, QR codes, and more. This wide range of payment methods ensures your customers can choose the option that suits them best, promoting faster payments.

Many small and medium business owners have significantly reduced payment delays by using Billtick’s payment reminder feature. By keeping track of dues and maintaining cash flow, Billtick helps businesses adjust their financial strategies accordingly. 

The software’s ease of use has made it the go-to solution for small businesses, with many relying solely on Billtick for all their invoicing and billing needs. As one of the best GST-compliant billing software solutions, Billtick eliminates the need for multiple apps, streamlining operations and enhancing business efficiency.`}
              min={180}
              ideal={180}
              max={200}
              readMoreText="Read more"
            />
          </div>
        </div>
        {/* <div className="detail reverse">
          <div>
            <h1>GST filing made simpler and faster</h1>
            <ReadMoreReact
              text={`Filing GST is a challenge for business owners and is a long process if done manually. Using Billtick billing software, you can create GST reports and make the GST filing process seamless.

Many business owners spend their time and efforts every month. It is to ensure that they comply with the tax laws. After all, they must keep track of their monthly invoices, expenses, and accounting details. Also, manually enter them to file GST returns.

Billtick changes it all by helping create dedicated GSTR reports and saves time using automation. Billtick can help you create reports like GSTR1, GSTR2, GSTR3, GSTR4, and GSTR9 from the app itself.

Using the data you had saved when you created invoices for your customers and entered expenses. Using professional billing software to create GST reports saves time for every business owner. With this, they can rely on automation to complete all accounting tasks without errors.

As you create GST bills throughout the year, the app automatically detect the GST applicable to the product. It helps comply with GST laws to ensure that every transaction is recorded. Further, it ensures correct GST figures are entered. The billing software further eliminates manual errors with automation. It is beneficial when you cannot hire an extra employee to care for your accounting.

To ensure that you can file GST timely and no data loss happens, you can keep a backup. Using Vyapar, your business data is safe. It makes you feel stress-free when you have to file GST as you can restore data anytime. Using Vyapar, you can create backups in both Google Drive and local storage to keep it secure.`}
              min={180}
              ideal={180}
              max={200}
              readMoreText="Read more"
            />
          </div>
          <img
            src="https://vyaparapp.in/v/z/wp-content/uploads/2024/05/gst_filing_made_simplerAndFaster.webp"
            alt=""
          />
        </div> */}
      </div>
      <h1 className="asked">Frequently Asked Questions</h1>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              What is Billtick billing software?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                {" "}
                Billtick is an all-in-one billing and accounting software
                designed to help small and medium-sized businesses manage their
                invoicing, inventory, and GST compliance efficiently.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              How can I create invoices with Billtick?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                With Billtick, you can create professional invoices in just a
                few steps. The software offers 10+ customizable GST-compliant
                invoice formats, which can be easily shared with customers via
                WhatsApp, email, or printed in various sizes like A4, A5, and
                more.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Does Billtick support GST-compliant invoicing?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Yes, Billtick is fully GST-compliant. You can generate GST
                invoices and reports that adhere to the latest Goods and
                Services Tax laws in India, making it easier to manage taxes for
                your business.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              What payment methods are supported in Billtick?
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Billtick supports a wide range of payment options including
                cash, credit cards, debit cards, e-wallets, NEFT, RTGS, UPI, and
                QR codes, giving your customers flexibility in how they pay.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              Can I track inventory with Billtick?
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Yes, Billtick includes robust inventory management features that
                allow you to track stock levels, batch numbers, expiry dates,
                and more. This helps ensure you have the right stock available
                and prevents losses due to overstocking or expiry.
              </p>
              {/* <ol>
                <li>Milestone Billing.</li>
                <li>Progress Billing.</li>
                <li>Sub-line-item Billing.</li>
                <li>Billing on Completion.</li>
                <li>Billing for ongoing Services.</li>
                <li>Prorated Billing.</li>
              </ol> */}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSix">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              Is Billtick easy to use for someone without accounting knowledge?
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Yes, Billtick is designed to be user-friendly, even for users
                without an accounting background. The software simplifies
                complex tasks like invoicing, payment tracking, and inventory
                management, saving you time and effort.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSeven">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              Can I access Billtick offline?
            </button>
          </h2>
          <div
            id="collapseSeven"
            className="accordion-collapse collapse"
            aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Yes, Billtick allows you to use its features both online and
                offline. This ensures you can manage your billing and inventory
                processes even without an internet connection.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingEight">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              Can I send payment reminders with Billtick?
            </button>
          </h2>
          <div
            id="collapseEight"
            className="accordion-collapse collapse"
            aria-labelledby="headingEight"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Yes, Billtick allows you to send automatic payment reminders to
                your customers via WhatsApp or email, helping you recover dues
                and maintain cash flow.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* </div> */}
    </div>
  );
};

export default HomePage;
