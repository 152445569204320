export const inventoryreportdata = [
  {
    id: 1,
    img: "assets/img/products/stock-img-01.png",
    productName: "Lenovo 3rd Generation",
    sku: "PT001",
    category: "Computers",
    brand: "N/D",
    unit: "pc",
    instockQty: "100",
  },
  {
    id: 2,
    productName: "Bold V3.2",
    img: "assets/img/products/stock-img-06.png",
    sku: "PT002",
    category: "Accessories",
    brand: "N/D",
    unit: "pc",
    instockQty: "150",
  },
  {
    id: 3,
    img: "assets/img/products/stock-img-02.png",
    productName: "Nike Jordan",
    sku: "PT003",
    category: "Shoe",
    brand: "N/D",
    unit: "pc",
    instockQty: "170",
  },
  {
    id: 4,
    img: "assets/img/products/stock-img-03.png",
    productName: "Apple Series 5 Watch",
    sku: "PT004",
    category: "Accessories",
    brand: "N/D",
    unit: "pc",
    instockQty: "120",
  },
  {
    id: 5,
    img: "assets/img/products/stock-img-04.png",
    productName: "Amazon Echo Dot",
    sku: "PT005",
    category: "Accessories",
    brand: "N/D",
    unit: "pc",
    instockQty: "80",
  },
  {
    id: 6,
    img: "assets/img/products/stock-img-05.png",
    productName: "Lobar Handy",
    sku: "PT006",
    category: "Furnitures",
    brand: "N/D",
    unit: "pc",
    instockQty: "200",
  },
  {
    id: 7,
    img: "assets/img/products/expire-product-01.png",
    productName: "Red Premium Handy",
    sku: "PT007",
    category: "Accessories",
    brand: "N/D",
    unit: "pc",
    instockQty: "230",
  },
  {
    id: 8,
    img: "assets/img/products/expire-product-02.png",
    productName: "Iphone 14 Pro",
    sku: "PT008",
    category: "Phone",
    brand: "N/D",
    unit: "pc",
    instockQty: "370",
  },
  {
    id: 9,
    img: "assets/img/products/expire-product-03.png",
    productName: "Black Slim 200",
    sku: "PT009",
    category: "Furnitures",
    brand: "N/D",
    unit: "pc",
    instockQty: "260",
  },
  {
    id: 10,
    img: "assets/img/products/expire-product-04.png",
    productName: "Woodcraft Sandal",
    sku: "PT010",
    category: "Bags",
    brand: "N/D",
    unit: "pc",
    instockQty: "340",
  },
];
