import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../firebase";
// import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { createUser } from "../../../service/api";
import { setToken, setUser } from "../../../core/redux/action";
import { useDispatch } from "react-redux";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import OtpInput from "react-otp-input";
import { TiArrowSortedUp } from "react-icons/ti";
// import InputMask from "react-input-mask";

const RegisterThree = () => {
  const dispatch = useDispatch();
  const route = all_routes;
  // const [passwordVisibility, setPasswordVisibility] = useState({
  //   password: false,
  //   confirmPassword: false,
  // });

  // const togglePasswordVisibility = (field) => {
  //   setPasswordVisibility((prevState) => ({
  //     ...prevState,
  //     [field]: !prevState[field],
  //   }));
  // };
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  // console.log("%c Line:33 🍡 phone", "color:#ea7e5c", phone);
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
      size: "invisible",
      callback: (response, error) => {
        console.log("error: ", error);
        console.log("response: ", response);
      },
    });
  };

  const handleSend = (event) => {
    event.preventDefault();
    if (!phone) {
      toast.error("Please Enter Number");
      return;
    } else if (phone?.length < 10) {
      toast.error("Please Enter Valid Number");
      return;
    }
    setHasFilled(true);
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    console.log("appVerifier: ", appVerifier);
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifyOtp = () => {
    // let otp = event.target.value;
    // setOtp(event);
    // console.log("otp: ", otp);

    // if (otp.length < 6) {
    //   toast.error("Enter valid OTP");
    //   return;
    // }
     createUser({ mobile: phone })
       .then((res) => {
         // console.log("%c Line:75 🥟 res", "color:#e41a6a", res);
         if (res?.status) {
           navigate(route.dashboard);
           toast.success("User signed in successfully");
           localStorage.setItem("token", res?.data?.token);
           dispatch(setToken(res?.data?.token));
           dispatch(setUser(res?.data?.checkMobile));
         }
       })
       .catch((e) => {
         console.log("%c Line:78 🍋 e", "color:#b03734", e);
       });
       return
    // if (otp.length === 6) {
    //   // verifu otp
    //   let confirmationResult = window.confirmationResult;
    //   confirmationResult
    //     .confirm(otp)
    //     .then((result) => {
    //       console.log("result: ", result);
    //       // User signed in successfully.
    //       let user = result.user;
    //       console.log(user);
    //       createUser({ mobile: phone })
    //         .then((res) => {
    //           // console.log("%c Line:75 🥟 res", "color:#e41a6a", res);
    //           if (res?.status) {
    //             navigate(route.dashboard);
    //             toast.success("User signed in successfully");
    //             localStorage.setItem("token", res?.data?.token);
    //             dispatch(setToken(res?.data?.token));
    //           }
    //         })
    //         .catch((e) => {
    //           console.log("%c Line:78 🍋 e", "color:#b03734", e);
    //         });
    //     })
    //     .catch((error) => {
    //       console.log("error: ", error.code);
    //       if (error.code === "auth/invalid-verification-code") {
    //         toast.error("Invalid Code. Please try again.");
    //       } else if (error.code === "auth/code-expired") {
    //         toast.error("The verification code has expired.");
    //       } else {
    //         toast.error("Verification failed. Please try again.");
    //       }
    //     });
    // }
  };
  // useEffect(() => {
  //   if (otp) {
  //     verifyOtp();
  //   }
  // }, [otp]);

  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper login-new">
          <div className="login-content user-login">
            <div className="login-logo">
              {/* <ImageWithBasePath src="assets/img/logo/logo.png" alt="img" /> */}
              <Link to={route.dashboard} className="login-logo logo-white">
                <ImageWithBasePath src="assets/img/logo-white.png" alt />
              </Link>
            </div>
            {/* <form> */}
            <div className="login-userset">
              <div className="login-userheading">
                <div className="login-logo">
                  <ImageWithBasePath src="assets/img/logo/logo.png" alt="img" />
                </div>{" "}
                <h3>Welcome to BillTick</h3>
                {hasFilled && <h5>{phone}</h5>}
                {/* <h4>Welcome to BillClick </h4> */}
              </div>
              {!hasFilled ? (
                <>
                  <div className="form-login">
                    <label>Phone Number</label>
                    <div className="form-addons">
                      {/* <input
                          type="tel"
                          value={phone}
                          onChange={(event) => setPhone(event.target.value)}
                          className="form-control"
                        /> */}
                      <PhoneInput
                        // type="tel"
                        placeholder="Enter phone number"
                        defaultCountry="IN"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e);
                        }}
                        className="form-control phone-input"
                      />
                      {/*} <span className="form-text text-muted">
                          e.g 8748819201
                        </span>*/}

                      {/* <ImageWithBasePath
                          src="assets/img/icons/user-icon.svg"
                          alt="img"
                        /> */}
                    </div>
                  </div>
                  <div className="otp-btns">
                    <p
                      onClick={() => {
                        navigate(-1);
                      }}
                      className="Tab"
                    >
                      <TiArrowSortedUp className="left" />
                      Back
                    </p>
                    <p
                      onClick={(e) => {
                        handleSend(e);
                      }}
                      className="Tab"
                    >
                      {!hasFilled ? "Get OTP" : "Verify OTP"}
                      <TiArrowSortedUp className="right" />
                    </p>
                  </div>
                  {/* <div className="form-login">
                    <Button
                      onClick={(e) => {
                        handleSend(e);
                      }}
                      to={route.signin}
                      className="btn btn-login"
                    >
                      {!hasFilled ? "Get OTP" : "Verify OTP"}
                    </Button>
                  </div> */}
                </>
              ) : (
                <>
                  <div className="input-block mb-3 mb-0">
                    <label>Verify OTP</label>
                    {/* <input
                        className="form-control"
                        // mask="999999"
                        value={otp}
                        type="number"
                        maxLength={6}
                        onChange={verifyOtp}
                      ></input> */}
                    <OtpInput
                      value={otp}
                      onChange={(e) => {
                        setOtp(e);
                      }}
                      numInputs={6}
                      // renderSeparator={<span>-</span>}
                      renderInput={(props) => (
                        <input {...props} className="otp-input" />
                      )}
                      // isInputNum={true}
                    />
                    <div className="otp-btns">
                      <p onClick={(e) => handleSend(e)} className="Tab">
                        {/* <TiArrowSortedUp className="left" /> */}
                        Resend
                      </p>
                      <p onClick={() => verifyOtp()} className="Tab">
                        Verify OTP
                        <TiArrowSortedUp className="right" />
                      </p>
                    </div>
                    {/* <div className="form-otp">
                      <Button
                        onClick={(e) => handleSend(e)}
                        className="btn btn-danger"
                      >
                        Resend
                      </Button>
                      <Button
                        onClick={() => verifyOtp()}
                        className="btn btn-success"
                      >
                        Verify OTP
                      </Button>
                    </div> */}
                  </div>
                </>
              )}
              <div id="recaptcha"></div>

              {/* <div className="form-login">
                  <label>Email Address</label>
                  <div className="form-addons">
                    <input type="text" className="form-control" />
                    <ImageWithBasePath
                      src="assets/img/icons/mail.svg"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="form-login">
                  <label>Password</label>
                  <div className="pass-group">
                    <input
                      type={passwordVisibility.password ? "text" : "password"}
                      className="pass-input form-control"
                    />
                    <span
                      className={`fas toggle-password ${
                        passwordVisibility.password ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={() => togglePasswordVisibility("password")}
                    ></span>
                  </div>
                </div>
                <div className="form-login">
                  <label>Confirm Passworrd</label>
                  <div className="pass-group">
                    <input
                      type={
                        passwordVisibility.confirmPassword ? "text" : "password"
                      }
                      className="pass-input form-control"
                    />
                    <span
                      className={`fas toggle-password ${
                        passwordVisibility.confirmPassword
                          ? "fa-eye"
                          : "fa-eye-slash"
                      }`}
                      onClick={() =>
                        togglePasswordVisibility("confirmPassword")
                      }
                    ></span>
                  </div>
                </div>
                <div className="form-login authentication-check">
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="custom-control custom-checkbox justify-content-start">
                        <div className="custom-control custom-checkbox">
                          <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                            <input type="checkbox" />
                            <span className="checkmarks" />I agree to the{" "}
                            <Link to="#" className="hover-a">
                              Terms &amp; Privacy
                            </Link>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

              <div className="signinform">
                {/* <h4>
                    Already have an account ?{" "}
                    <Link to={route.signinthree} className="hover-a">
                      Sign In Instead
                    </Link>
                  </h4> */}
              </div>
              {/* <div className="form-setlogin or-text">
                  <h4>OR</h4>
                </div>
                <div className="form-sociallink">
                  <ul className="d-flex">
                    <li>
                      <Link to="#" className="facebook-logo">
                        <ImageWithBasePath
                          src="assets/img/icons/facebook-logo.svg"
                          alt="Facebook"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <ImageWithBasePath
                          src="assets/img/icons/google.png"
                          alt="Google"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="apple-logo">
                        <ImageWithBasePath
                          src="assets/img/icons/apple-logo.svg"
                          alt="Apple"
                        />
                      </Link>
                    </li>
                  </ul>
                </div> */}
            </div>
            {/* </form> */}
          </div>
          <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
            <p>Copyright © 2024 BillTick. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterThree;
