import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { addUser, getUser } from "../../service/api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUser } from "../../core/redux/action";

const Profile = () => {
  // const [isPasswordVisible, setPasswordVisible] = useState(false);

  // const togglePasswordVisibility = () => {
  //   setPasswordVisible((prevState) => !prevState);
  // };
  const [name, setName] = useState();
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState(false);
  const [image, setImage] = useState();
  console.log('image: ', image);
  const [imagePreview, setImagePreview] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleImage = (e) => {
    if (e) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e);
    }
  };
  const getUserDetail = () => {
    getUser()
      .then((res) => {
        console.log("%c Line:14 🥚 res", "color:#4fff4B", res);
        if (res?.status) {
          setName(res?.data?.name);
          setEmail(res?.data?.email);
          setImage(res?.data?.profileImage);
          setImagePreview(res?.data?.profileImage);
          dispatch(setUser(res?.data));
        }
      })
      .catch((e) => {
        console.log("%c Line:17 🍣 e", "color:#33a5ff", e);
      });
  };

  const handleData = () => {
    if (!name) {
      setNameError(true);
    }
    if (!email) {
      setEmailError(true);
    }
    if (nameError || emailError) {
      return;
    }

    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    if (image && typeof image !== "string") formdata.append("images", image);
    setLoading(true);
    addUser(formdata)
      .then((res) => {
        console.log("%c Line:28 🍯 res", "color:#93c0a4", res);
        if (res?.status) {
          getUserDetail();
          setName();
          setEmail();
          setImage();
          // setImagePreview();
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        console.log("%c Line:31 🍆 e", "color:#ed9ec7", e);
        toast.error(e?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Profile</h4>
            <h6>User Profile</h6>
          </div>
        </div>
        {/* /product list */}
        <div className="card">
          <div className="card-body">
            <div className="profile-set">
              <div className="profile-head"></div>
              <div className="profile-top">
                <div className="profile-content">
                  <div className="profile-contentimg">
                    {imagePreview ? (
                      <img src={imagePreview} alt="img" id="blah" />
                    ) : (
                      <ImageWithBasePath
                        src={"assets/img/customer/customer5.jpg"}
                        alt="img"
                        id="blah"
                      />
                    )}
                    <div className="profileupload">
                      <input
                        type="file"
                        id="imgInp"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                          handleImage(e.target.files[0]);
                        }}
                      />
                      <Link to="#">
                        <ImageWithBasePath
                          src="assets/img/icons/edit-set.svg"
                          alt="img"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="profile-contentname">
                    <h2>{name}</h2>
                    <h4>Updates Your Photo and Personal Details.</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameError(false);
                    }}
                    style={
                      nameError
                        ? { borderColor: "#dc3545" }
                        : { borderColor: "#e9edf6" }
                    }
                    className={`form-control ${nameError && "is-invalid"}`}
                    // defaultValue="William"
                  />
                  <div className="invalid-feedback">
                    {nameError ? "Name Required" : ""}
                  </div>{" "}
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError(false);
                    }}
                    style={
                      emailError
                        ? { borderColor: "#dc3545" }
                        : { borderColor: "#e9edf6" }
                    }
                    className={`form-control ${emailError && "is-invalid"}`}
                    // defaultValue="Castilo"
                  />
                  <div className="invalid-feedback">
                    {emailError ? "Email Required" : ""}
                  </div>{" "}
                </div>
              </div>
              {/* <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    defaultValue="william@example.com"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label className="form-label">Phone</label>
                  <input type="text" defaultValue="+1452 876 5432" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label className="form-label">User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="William Castilo"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label className="form-label">Password</label>
                  <div className="pass-group">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className="pass-input form-control"
                    />
                    <span
                      className={`fas toggle-password ${
                        isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={togglePasswordVisibility}
                    ></span>
                  </div>
                </div>
              </div> */}
              <div className="col-12">
                <Link
                  onClick={handleData}
                  disabled={loading}
                  className="btn btn-submit me-2"
                >
                  Submit
                </Link>
                <Link to="#" className="btn btn-cancel">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

export default Profile;
