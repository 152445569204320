/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  File,
  Filter,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
} from "feather-icons-react/build/IconComponents";
import { setToogleHeader } from "../../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import EditPurchases from "../../../core/modals/purchases/editpurchases";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../../core/pagination/datatable";
// import { purchaseslist } from "../../../core/json/purchaselistdata";
import {
  deleteInvoice,
  downloadInvoice,
  getInvoice,
} from "../../../service/api";
import AddInvoice from "../../../core/modals/Invoice/AddInvoice";
import { toast } from "react-toastify";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Calendar } from "react-feather";
import { Input } from "antd";
import moment from "moment";
import axios from "axios";
import { SERVER_URL } from "../../../config";

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};
const InvoiceList = () => {
  // const purchasedata = purchaseslist;
  // console.log("%c Line:29 🧀 purchasedata", "color:#33a5ff", purchasedata);
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleDownload = (e) => {
    window.location.href = `${SERVER_URL}/user/invoice/generatePDF/${e}`;
  };

  // const handleDownload = (e) => {
  //   downloadInvoice(e)
  //     .then((res) => {
  //       console.log("%c Line:65 🎂 res", "color:#42b983", res);
  //     })
  //     .catch((e) => {
  //       console.log("%c Line:68 🍐 e", "color:#fca650", e);
  //     });
  // };

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "businessCustomer",
      render: (text) => <div>{text?.name}</div>,
      sorter: (a, b) => a.businessCustomer.length - b.businessCustomer.length,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      sorter: (a, b) => a.totalAmount.length - b.totalAmount.length,
    },
    {
      title: "Final Amount",
      dataIndex: "finalAmount",
      sorter: (a, b) => a.finalAmount.length - b.finalAmount.length,
    },
    {
      title: "Status",
      dataIndex: "invoiceStatus",
      render: (text) => (
        <span
          className={`badges ${
            text === "PARTIALPAID" || text === "PAID"
              ? "status-badge"
              : "badge-bgdanger"
          }`}
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },

    {
      title: "Due",
      dataIndex: "remainingAmount",
      sorter: (a, b) => a.remainingAmount.length - b.remainingAmount.length,
    },
    // {
    //   title: "CreatedBy",
    //   dataIndex: "createdBy",
    //   render: (text) => (
    //     <span
    //       className={`badges ${
    //         text === "Paid" ? "badge-linesuccess" : "badge-linedangered"
    //       }`}
    //     >
    //       {text}
    //     </span>
    //   ),
    //   sorter: (a, b) => a.createdBy.length - b.createdBy.length,
    // },

    {
      title: "Actions",
      // dataIndex: "actions",
      key: "actions",
      render: (row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              to=""
              onClick={() => handleDownload(row?._id)}
            >
              <i data-feather="download" className="feather-download"></i>
            </Link>
            <Link className="me-2 p-2" to="#">
              <i data-feather="eye" className="feather-eye"></i>
            </Link>
            <Link to={`/add-invoice/${row?._id}`} className="me-2 p-2">
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => showConfirmationAlert(row?._id)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const [invoiceData, setInvoiceData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [selectedData, setSelectedData] = useState("");

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [calendar, setCalendar] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    if (ranges.selection.startDate) {
      // setStartDate(moment(ranges.selection.startDate).format("DD/MM/YYYY"));
      setStartDate(new Date(ranges.selection.startDate));
    }
    if (ranges.selection.endDate) {
      setEndDate(new Date(ranges.selection.endDate));
      // setEndDate(moment(ranges.selection.endDate).format("DD/MM/YYYY"));
    }
  };

  const getData = () => {
    let body = {
      page: page,
      sizePerPage: sizePerPage,
      // fromDate: "6/23/2024",
      // toDate: "6/30/2024",
    };
    if (startDate && endDate) {
      body.fromDate = moment(startDate).format("M/D/YYYY");
      body.toDate = moment(endDate).format("M/D/YYYY");
    }
    getInvoice(body)
      .then((res) => {
        console.log("%c Line:42 🍅 res", "color:#ea7e5c", res);
        if (res?.status) {
          setInvoiceData(res?.data?.docs);
          setTotal(res?.data?.totalDocs);
        }
      })
      .catch((e) => {
        console.log("%c Line:45 🍬 e", "color:#4fff4B", e);
      });
  };

  useEffect(() => {
    getData();
  }, [page, sizePerPage, startDate, endDate]);

  const handleChangePage = (p, s) => {
    console.log("p,s: ", p, s);
    setSizePerPage(s);
    setPage(p);
  };

  const oldandlatestvalue = [
    { value: "date", label: "Sort by Date" },
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
  ];

  // const suppliername = [
  //   { value: "chooseSupplier", label: "Choose Supplier Name" },
  //   { value: "apexComputers", label: "Apex Computers" },
  //   { value: "beatsHeadphones", label: "Beats Headphones" },
  //   { value: "dazzleShoes", label: "Dazzle Shoes" },
  //   { value: "bestAccessories", label: "Best Accessories" },
  // ];
  const status = [
    { value: "chooseStatus", label: "Choose Status" },
    { value: "received", label: "Received" },
    { value: "ordered", label: "Ordered" },
    { value: "pending", label: "Pending" },
  ];
  const refrencecode = [
    { value: "enterReference", label: "Enter Reference" },
    { value: "PT001", label: "PT001" },
    { value: "PT002", label: "PT002" },
    { value: "PT003", label: "PT003" },
  ];
  const paymentstatus = [
    { value: "choosePaymentStatus", label: "Choose Payment Status" },
    { value: "paid", label: "Paid" },
    { value: "partial", label: "Partial" },
    { value: "unpaid", label: "Unpaid" },
  ];
  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (e) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteInvoice(e)
          .then((res) => {
            if (res?.status) {
              toast.success(res?.message);
              getData();
              MySwal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                className: "btn btn-success",
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            }
          })
          .catch((e) => {
            console.log("%c Line:244 🍏 e", "color:#6ec1c2", e);
          });
      } else {
        MySwal.close();
      }
    });
  };

  let domNode = useClickOutside(() => {
    setCalendar(false);
  });
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Invoice List</h4>
                <h6>Manage your Invoice</h6>
              </div>
            </div>
            <ul className="table-top-head">
              {/* <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li> */}
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn">
                <Link
                  to="/add-invoice"
                  onClick={() => {
                    setEdit(false);
                    setSelectedData("");
                  }}
                  className="btn btn-added"
                  // data-bs-toggle="modal"
                  // data-bs-target="#add-invoice"
                >
                  <PlusCircle className="me-2" />
                  Add New Invoice
                </Link>
              </div>
              {/* <div className="page-btn import">
                <Link
                  to="#"
                  className="btn btn-added color"
                  data-bs-toggle="modal"
                  data-bs-target="#view-notes"
                >
                  <Download className="me-2" />
                  Import Purchase
                </Link>
              </div> */}
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="search-path">
                  <Link
                    className={`btn btn-filter ${
                      isFilterVisible ? "setclose" : ""
                    }`}
                    id="filter_search"
                  >
                    <Filter
                      className="filter-icon"
                      onClick={toggleFilterVisibility}
                    />
                    <span onClick={toggleFilterVisibility}>
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div>
                <div className="form-sort">
                  <Sliders className="info-img" />
                  <Select
                    className="img-select"
                    classNamePrefix="react-select"
                    options={oldandlatestvalue}
                    placeholder="Newest"
                  />
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div
                      className="col-lg-3 col-sm-6 col-12"
                      style={{ position: "relative" }}
                      ref={domNode}
                    >
                      <div className="input-blocks">
                        <Calendar className="info-img" />
                        <Input
                          className="img-select"
                          classNamePrefix="react-select"
                          placeholder="Choose Date"
                          value={
                            startDate && endDate
                              ? `${moment(startDate).format(
                                  "DD/MM/YYYY"
                                )} - ${moment(endDate).format("DD/MM/YYYY")}`
                              : ""
                          }
                          onClick={() => setCalendar(!calendar)}
                        />
                      </div>
                      {calendar && (
                        <div
                          ref={domNode}
                          style={{
                            position: "absolute",
                            top: "40px",
                            zIndex: "1000",
                            border: "1px solid #dbe0e6",
                          }}
                        >
                          <DateRangePicker
                            ranges={[selectionRange]}
                            onChange={handleSelect}
                          />
                        </div>
                      )}
                    </div>
                    {/* <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />
                        <Select
                          className="img-select"
                          options={status}
                          classNamePrefix="react-select"
                          placeholder="Choose Status"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <File className="info-img" />
                        <Select
                          className="img-select"
                          options={refrencecode}
                          classNamePrefix="react-select"
                          placeholder="Enter Reference"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <i className="fas fa-money-bill info-img" />
                        <Select
                          className="img-select"
                          t
                          options={paymentstatus}
                          classNamePrefix="react-select"
                          placeholder="Choose Payment Status"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div
                className="table-responsive product-list"
                style={{ zIndex: "100" }}
              >
                <Table
                  columns={columns}
                  dataSource={invoiceData}
                  pagination={{
                    pageSize: sizePerPage,
                    current: page,
                    total: total,
                    hideOnSinglePage: true,
                    onChange: handleChangePage,
                    showSizeChanger: true,
                    pageSizeOptions: ["1", "2", "3"],
                  }}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddInvoice getData={getData} edit={edit} selectedData={selectedData} />
      <EditPurchases />
    </div>
  );
};

export default InvoiceList;
