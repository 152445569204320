export const expenselist = [
  {
    id: "1",
    categoryName: "Employee Benefits",
    reference: "PT001",
    date: "19 Jan 2023",
    status: "Active",
    amount: "$550",
    description: "Employee Vehicle",
    action: "",
  },
  {
    id: "2",
    categoryName: "Foods & Snacks",
    reference: "PT002",
    date: "27 Jan 2023",
    status: "Active",
    amount: "$570",
    description: "Employee Foods",
    action: "",
  },
  {
    id: "3",
    categoryName: "Entertainment",
    reference: "PT003",
    date: "03 Feb 2023",
    status: "Active",
    amount: "$400",
    description: "Office Vehicle",
    action: "",
  },
  {
    id: "4",
    categoryName: "Office Expenses & Postage",
    reference: "PT004",
    date: "17 Feb 2023",
    status: "Inactive",
    amount: "$750",
    description: "Employee Foods",
    action: "",
  },
  {
    id: "5",
    categoryName: "Employee Benefits",
    reference: "PT005",
    date: "14 Mar 2023",
    status: "Inactive",
    amount: "$470",
    description: "Employee Vehicle",
    action: "",
  },
  {
    id: "6",
    categoryName: "Foods & Snacks",
    reference: "PT006",
    date: "28 Mar 2023",
    status: "Active",
    amount: "$200",
    description: "Employee Foods",
    action: "",
  },
  {
    id: "7",
    categoryName: "Entertainment",
    reference: "PT007",
    date: "06 Apr 2023",
    status: "Inactive",
    amount: "$380",
    description: "Office Vehicle",
    action: "",
  },
  {
    id: "8",
    categoryName: "Office Expenses & Postage",
    reference: "PT008",
    date: "19 Apr 2023",
    status: "Active",
    amount: "$620",
    description: "Employee Foods",
    action: "",
  },
  {
    id: "9",
    categoryName: "Employee Benefits",
    reference: "PT009",
    date: "08 May 2023",
    status: "Inactive",
    amount: "$430",
    description: "Employee Vehicle",
    action: "",
  },
  {
    id: "10",
    categoryName: "Foods & Snacks",
    reference: "PT010",
    date: "23 May 2023",
    status: "Active",
    amount: "$120",
    description: "Employee Foods",
    action: "",
  },
  {
    id: "11",
    categoryName: "Entertainment",
    reference: "PT011",
    date: "24 Nov 2023",
    status: "Inactive",
    amount: "$620",
    description: "Office Vehicle",
    action: "",
  },
  {
    id: "12",
    categoryName: "Office Expenses & Postage",
    reference: "PT012",
    date: "19 Nov 2023",
    status: "Active",
    amount: "$430",
    description: "Employee Foods",
    action: "",
  },
];
