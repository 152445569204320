// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCYisNP_VpOniHVvZQIrNI93odYJ1WxTak",
    authDomain: "billclick-24f6b.firebaseapp.com",
    projectId: "billclick-24f6b",
    storageBucket: "billclick-24f6b.appspot.com",
    messagingSenderId: "714227706583",
    appId: "1:714227706583:web:cc0441bcb80643b4e55e92",
    measurementId: "G-8P7RFKKDLT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);