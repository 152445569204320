import axios from "axios";
import { SERVER_URL } from "../config/index";
// import store from "../core/redux/store";
// import actions from "../redux/user/actions";
const request = axios.create({
  baseURL: SERVER_URL,
  timeout: 1000000,
});
let requests = [];

request.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("token");
    // const authToken =
    //   "eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2NjU1NWMyMzhlOGY4MTM4NzE0ZWFlMGMiLCJtb2JpbGUiOiIrOTE5NzM3NTkwNjkyIiwicm9sZSI6IlVTRVIiLCJpc0FjdGl2ZSI6dHJ1ZSwic3RhdHVzIjoxLCJpc0RlbGV0ZWQiOmZhbHNlLCJjcmVhdGVkQXQiOiIyMDI0LTA1LTI4VDA0OjIyOjU5LjEzM1oiLCJ1cGRhdGVkQXQiOiIyMDI0LTA2LTAyVDEyOjQ5OjMxLjUyNloiLCJfX3YiOjAsImVtYWlsIjoic2FnYXIucnVkYW5pMThAZ21haWwuY29tIiwibmFtZSI6IlNhZ2FyIFJ1ZGFuaSIsInByb2ZpbGVJbWFnZSI6Imh0dHBzOi8vc3RvcmFnZS5nb29nbGVhcGlzLmNvbS9iaWxsY2xpY2wvdXNlci1wcm9maWxlLzE3MTczMzI1Njg2MDAucG5nIn0.oW7AN3ptsQP0uFX3WKJTpL1dJ2uiPo2_5H3TUV7JdHo";

    if (authToken) {
      config.headers.Authorization = `${authToken}`;
    }
    // store.dispatch(actions.setLoading(true));

    requests.push(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
request.interceptors.response.use(
  function (response) {
    // store.dispatch(actions.setLoading(false));
    return response;
  },
  function (error) {
    // store.dispatch(actions.setLoading(false));
    return Promise.reject(error);
  }
);
export default request;
