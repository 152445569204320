import request from "./request";

export const getUser = (data) => {
  return new Promise((resolve, reject) => {
    request
      .get("/user/profile", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addUser = (data) => {
  return new Promise((resolve, reject) => {
    request
      .post("/user/profile", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const createUser = (data) => {
  return new Promise((resolve, reject) => {
    request
      .post("/user/auth/signup", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const addBusiness = (data) => {
  return new Promise((resolve, reject) => {
    request
      .post("/user/business", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const updateBusiness = (id, data) => {
  return new Promise((resolve, reject) => {
    request
      .put("/user/business/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getBusiness = () => {
  return new Promise((resolve, reject) => {
    request
      .get("/user/business")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteBusiness = (id, data) => {
  return new Promise((resolve, reject) => {
    request
      .delete("/user/business/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//PRODUCT TYPE NUMBER
export const addProductType = (data) => {
  return new Promise((resolve, reject) => {
    request
      .post("/user/product-type-number", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const updateProductType = (id, data) => {
  return new Promise((resolve, reject) => {
    request
      .put("/user/product-type-number/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getProductType = (params) => {
  return new Promise((resolve, reject) => {
    request
      .get("/user/product-type-number", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteProductType = (id, data) => {
  return new Promise((resolve, reject) => {
    request
      .delete("/user/product-type-number/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//PRODUCT
export const addProduct = (data) => {
  return new Promise((resolve, reject) => {
    request
      .post("/user/product", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const updateProduct = (id, data) => {
  return new Promise((resolve, reject) => {
    request
      .put("/user/product/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getProduct = (params) => {
  return new Promise((resolve, reject) => {
    request
      .get("/user/product", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteProduct = (id, data) => {
  return new Promise((resolve, reject) => {
    request
      .delete("/user/product/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//CUSTOMER
export const addCustomer = (data) => {
  return new Promise((resolve, reject) => {
    request
      .post("/user/business-customer", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const updateCustomer = (id, data) => {
  return new Promise((resolve, reject) => {
    request
      .put("/user/business-customer/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getCustomer = (params) => {
  return new Promise((resolve, reject) => {
    request
      .get("/user/business-customer", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteCustomer = (id, data) => {
  return new Promise((resolve, reject) => {
    request
      .delete("/user/business-customer/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//INVOICE
export const addInvoice = (data) => {
  return new Promise((resolve, reject) => {
    request
      .post("/user/invoice", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const updateInvoice = (id, data) => {
  return new Promise((resolve, reject) => {
    request
      .put("/user/invoice/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getInvoice = (params) => {
  return new Promise((resolve, reject) => {
    request
      .get("/user/invoice", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getInvoiceById = (id) => {
  return new Promise((resolve, reject) => {
    request
      .get("/user/invoice/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteInvoice = (id, data) => {
  return new Promise((resolve, reject) => {
    request
      .delete("/user/invoice/" + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const downloadInvoice = (id) => {
  return new Promise((resolve, reject) => {
    request
      .get("/user/invoice/generatePDF/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
