export const printerSettingsData = [
    {
        id: '1',
        printerName: 'HP Printer',
        connectionType: 'Network',
        ipAddress: '151.00.1.22',
        port: 900,
      },
      {
        id: '2',
        printerName: 'Epson',
        connectionType: 'Network',
        ipAddress: '151.00.2.20',
        port: 700,
      }
]