export const invoicereportsdata = [
  {
    id: 1,
    invoiceNo: "INV001",
    customer: "Thomas",
    dueDate: "19 Jan 2023",
    amount: "$1000",
    paid: "$1000",
    amountDue: "$0.00",
    status: "Paid",
  },
  {
    id: 2,
    invoiceNo: "INV002",
    customer: "Rose",
    dueDate: "25 Jan 2023",
    amount: "$1500",
    paid: "$0.00",
    amountDue: "$1500",
    status: "Unpaid",
  },
  {
    id: 3,
    invoiceNo: "INV003",
    customer: "Benjamin",
    dueDate: "05 Feb 2023",
    amount: "$1800",
    paid: "$1800",
    amountDue: "$0.00",
    status: "Paid",
  },
  {
    id: 4,
    invoiceNo: "INV004",
    customer: "Kaitlin",
    dueDate: "15 Feb 2023",
    amount: "$2000",
    paid: "$1000",
    amountDue: "$1000",
    status: "Overdue",
  },
  {
    id: 5,
    invoiceNo: "INV005",
    customer: "Lilly",
    dueDate: "18 Mar 2023",
    amount: "$800",
    paid: "$800",
    amountDue: "$0.00",
    status: "Paid",
  },
  {
    id: 6,
    invoiceNo: "INV006",
    customer: "Freda",
    dueDate: "24 Mar 2023",
    amount: "$750",
    paid: "$0.00",
    amountDue: "$750",
    status: "Unpaid",
  },
  {
    id: 7,
    invoiceNo: "INV007",
    customer: "Alwin",
    dueDate: "12 Apr 2023",
    amount: "$1300",
    paid: "$1300",
    amountDue: "$0.00",
    status: "Paid",
  },
  {
    id: 8,
    invoiceNo: "INV008",
    customer: "Maybelle",
    dueDate: "24 Apr 2023",
    amount: "$1100",
    paid: "$1100",
    amountDue: "$0.00",
    status: "Paid",
  },
  {
    id: 9,
    invoiceNo: "INV009",
    customer: "Ellen",
    dueDate: "03 May 2023",
    amount: "$2300",
    paid: "$2300",
    amountDue: "$0.00",
    status: "Paid",
  },
  {
    id: 10,
    invoiceNo: "INV010",
    customer: "Grace",
    dueDate: "29 May 2023",
    amount: "$1700",
    paid: "$1700",
    amountDue: "$0.00",
    status: "Paid",
  },
];
