import {
  Box,
  ChevronUp,
  Edit,
  Eye,
  Filter,
  GitMerge,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  Trash2,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import Brand from "../../core/modals/inventory/brand";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { all_routes } from "../../Router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import { setToogleHeader, set_single_product } from "../../core/redux/action";
import {
  deleteProduct,
  getBusiness,
  getProduct,
  getProductType,
} from "../../service/api";
import { toast } from "react-toastify";

const ProductList = () => {
  // const dataSource = useSelector((state) => state.product_list);
  const [productData, setProductData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.toggle_header);
  const [businessData, setBusinessData] = useState([]);
  console.log("%c Line:34 🍉 businessData", "color:#ed9ec7", businessData);
  const [productType, setProductType] = useState([]);
  console.log("%c Line:36 🍢 productType", "color:#42b983", productType);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const route = all_routes;
  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    { value: "140923", label: "14 09 23" },
    { value: "110923", label: "11 09 23" },
  ];
  const productlist = [
    { value: "choose", label: "Choose Product" },
    { value: "lenovo", label: "Lenovo 3rd Generation" },
    { value: "nike", label: "Nike Jordan" },
  ];
  const categorylist = [
    { value: "choose", label: "Choose Category" },
    { value: "laptop", label: "Laptop" },
    { value: "shoe", label: "Shoe" },
  ];
  const subcategorylist = [
    { value: "choose", label: "Choose Sub Category" },
    { value: "computers", label: "Computers" },
    { value: "fruits", label: "Fruits" },
  ];
  const brandlist = [
    { value: "all", label: "All Brand" },
    { value: "lenovo", label: "Lenovo" },
    { value: "nike", label: "Nike" },
  ];
  const price = [
    { value: "price", label: "Price" },
    { value: "12500", label: "$12,500.00" },
    { value: "13000", label: "$13,000.00" },
  ];

  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const getData = () => {
    getProduct({ page: page, sizePerPage: sizePerPage })
      .then((res) => {
        console.log("%c Line:42 🍅 res", "color:#ea7e5c", res);
        if (res?.status) {
          setProductData(res?.data?.docs);
          setTotal(res?.data?.totalDocs);
        }
      })
      .catch((e) => {
        console.log("%c Line:45 🍬 e", "color:#4fff4B", e);
      });
  };

  useEffect(() => {
    getData();
  }, [page, sizePerPage]);

  const handleChangePage = (p, s) => {
    console.log("p,s: ", p, s);
    setSizePerPage(s);
    setPage(p);
  };

  const getDataBusiness = () => {
    getBusiness()
      .then((res) => {
        // console.log("%c Line:42 🍅 res", "color:#ea7e5c", res);
        if (res?.status) {
          if (res?.data?.length > 0) {
            setBusinessData(res?.data);
            // formik.setFieldValue("businessId", res?.data[0]?._id);
          } else {
            setBusinessData([]);
          }
        }
      })
      .catch((e) => {
        console.log("%c Line:45 🍬 e", "color:#4fff4B", e);
      });
  };

  const getDataProduct = () => {
    getProductType({ page: 1, sizePerPage: 1000 })
      .then((res) => {
        // console.log("%c Line:42 🍅 res", "color:#ea7e5c", res);
        if (res?.status) {
          setProductType(res?.data?.docs);
        } else {
          setProductType([]);
        }
      })
      .catch((e) => {
        console.log("%c Line:45 🍬 e", "color:#4fff4B", e);
      });
  };
  useEffect(() => {
    getDataBusiness();
    getDataProduct();
  }, []);
  const businessName = (e) => {
    let arr = businessData && businessData?.filter((obj) => obj?._id === e);
    return arr[0]?.name;
  };
  const productName = (e) => {
    let arr = productType && productType?.filter((obj) => obj?._id === e);
    return arr[0]?.name;
  };
  const columns = [
    // {
    //   title: "Product",
    //   dataIndex: "product",
    //   render: (text, record) => (
    //     <span className="productimgname">
    //       <Link to="/profile" className="product-img stock-img">
    //         <ImageWithBasePath alt="" src={record.productImage} />
    //       </Link>
    //       <Link to="/profile">{text}</Link>
    //     </span>
    //   ),
    //   sorter: (a, b) => a.product.length - b.product.length,
    // },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Business",
      dataIndex: "business",
      render: (row) => <div>{businessName(row)}</div>,
      sorter: (a, b) => a.business.length - b.business.length,
    },
    {
      title: "Product Type",
      dataIndex: "productTypeNumber",
      render: (row) => <div>{productName(row)}</div>,
      sorter: (a, b) => a.productTypeNumber.length - b.productTypeNumber.length,
    },
    {
      title: "Price",
      dataIndex: "price",
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: "SKU Number",
      dataIndex: "typeNumber",
      sorter: (a, b) => a.typeNumber.length - b.typeNumber.length,
    },
    {
      title: "GST %",
      dataIndex: "GSTPr",
      sorter: (a, b) => a.GSTPr.length - b.GSTPr.length,
    },
    // {
    //   title: "Created By",
    //   dataIndex: "createdby",
    //   render: (text, record) => (
    //     <span className="userimgname">
    //       <Link to="/profile" className="product-img">
    //         <ImageWithBasePath alt="" src={record.img} />
    //       </Link>
    //       <Link to="/profile">{text}</Link>
    //     </span>
    //   ),
    //   sorter: (a, b) => a.createdby.length - b.createdby.length,
    // },
    {
      title: "Action",
      // dataIndex: "action",
      render: (row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>
            <Link className="me-2 p-2" to={route.productdetails}>
              <Eye className="feather-view" />
            </Link>
            <Link
              className="me-2 p-2"
              onClick={() => {
                dispatch(set_single_product(row));
                navigate(route.editproduct);
              }}
              to={route.editproduct}
            >
              <Edit className="feather-edit" />
            </Link>
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => showConfirmationAlert(row)}
            >
              <Trash2 className="feather-trash-2" />
            </Link>
          </div>
        </div>
      ),
      sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (e) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProduct(e?._id)
          .then((res) => {
            if (res?.status) {
              getData();
              toast.success(res?.message);
              MySwal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                className: "btn btn-success",
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            }
          })
          .catch((e) => {
            console.log("%c Line:260 🍋 e", "color:#4fff4B", e);
          });
      } else {
        MySwal.close();
      }
    });
  };

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Product List</h4>
              <h6>Manage your products</h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip}>
                <Link>
                  <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top">
                  <ImageWithBasePath
                    src="assets/img/icons/excel.svg"
                    alt="img"
                  />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top">
                  <i data-feather="printer" className="feather-printer" />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top">
                  <RotateCcw />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                <Link
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  id="collapse-header"
                  className={data ? "active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setToogleHeader(!data));
                  }}
                >
                  <ChevronUp />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
          <div className="page-btn">
            <Link to={route.addproduct} className="btn btn-added">
              <PlusCircle className="me-2 iconsize" />
              Add New Product
            </Link>
          </div>
          {/* <div className="page-btn import">
            <Link
              to="#"
              className="btn btn-added color"
              data-bs-toggle="modal"
              data-bs-target="#view-notes"
            >
              <Download className="me-2" />
              Import Product
            </Link>
          </div> */}
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              <div className="search-path">
                <Link
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div>
              <div className="form-sort">
                <Sliders className="info-img" />
                <Select
                  className="img-select"
                  classNamePrefix="react-select"
                  options={options}
                  placeholder="14 09 23"
                />
              </div>
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <div className="row">
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="input-blocks">
                          <Box className="info-img" />
                          <Select
                            className="img-select"
                            classNamePrefix="react-select"
                            options={productlist}
                            placeholder="Choose Product"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="input-blocks">
                          <StopCircle className="info-img" />
                          <Select
                            className="img-select"
                            classNamePrefix="react-select"
                            options={categorylist}
                            placeholder="Choose Category"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="input-blocks">
                          <GitMerge className="info-img" />
                          <Select
                            className="img-select"
                            classNamePrefix="react-select"
                            options={subcategorylist}
                            placeholder="Choose Sub Category"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="input-blocks">
                          <StopCircle className="info-img" />
                          <Select
                            className="img-select"
                            classNamePrefix="react-select"
                            options={brandlist}
                            placeholder="Nike"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="input-blocks">
                          <i className="fas fa-money-bill info-img" />

                          <Select
                            className="img-select"
                            classNamePrefix="react-select"
                            options={price}
                            placeholder="Price"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="input-blocks">
                          <Link className="btn btn-filters ms-auto">
                            {" "}
                            <i
                              data-feather="search"
                              className="feather-search"
                            />{" "}
                            Search{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={productData}
                pagination={{
                  pageSize: sizePerPage,
                  current: page,
                  total: total,
                  hideOnSinglePage: true,
                  onChange: handleChangePage,
                  showSizeChanger: true,
                  pageSizeOptions: ["1", "2", "3"],
                }}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
        <Brand />
      </div>
    </div>
  );
};

export default ProductList;
