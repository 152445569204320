export const customerreportdata = [
  {
    id: "1",
    CustomerID: "CT_0001",
    CustomerName: "Thomas",
    Amount: "$1800",
    Paid: "$1800",
    DueAmount: "$0.00",
    Status: "Completed",
    PaymentStatus: "Paid",
  },
  {
    id: "2",
    CustomerID: "CT_0002",
    CustomerName: "Rose",
    Amount: "$120",
    Paid: "$0.00",
    DueAmount: "$120",
    Status: "Completed",
    PaymentStatus: "Unpaid",
  },
  {
    id: "3",
    CustomerID: "CT_0003",
    CustomerName: "Benjamin",
    Amount: "$2000",
    Paid: "$2000",
    DueAmount: "$0.00",
    Status: "Completed",
    PaymentStatus: "Paid",
  },
  {
    id: "4",
    CustomerID: "CT_0004",
    CustomerName: "Kaitlin",
    Amount: "$1300",
    Paid: "$1300",
    DueAmount: "$1300",
    Status: "Completed",
    PaymentStatus: "Overdue",
  },
  {
    id: "5",
    CustomerID: "CT_0005",
    CustomerName: "Lilly",
    Amount: "$400",
    Paid: "$0.00",
    DueAmount: "$400",
    Status: "Completed",
    PaymentStatus: "Unpaid",
  },
  {
    id: "6",
    CustomerID: "CT_0006",
    CustomerName: "Freda",
    Amount: "$700",
    Paid: "$700",
    DueAmount: "$700",
    Status: "Completed",
    PaymentStatus: "Overdue",
  },
  {
    id: "7",
    CustomerID: "CT_0007",
    CustomerName: "Alwin",
    Amount: "$800",
    Paid: "$800",
    DueAmount: "$0.00",
    Status: "Completed",
    PaymentStatus: "Paid",
  },
  {
    id: "8",
    CustomerID: "CT_0008",
    CustomerName: "Maybelle",
    Amount: "$300",
    Paid: "$300",
    DueAmount: "$0.00",
    Status: "Completed",
    PaymentStatus: "Paid",
  },
  {
    id: "9",
    CustomerID: "CT_0009",
    CustomerName: "Ellen",
    Amount: "$120",
    Paid: "$120",
    DueAmount: "$0.00",
    Status: "Completed",
    PaymentStatus: "Paid",
  },
  {
    id: "10",
    CustomerID: "CT_0010",
    CustomerName: "Grace",
    Amount: "$600",
    Paid: "$600",
    DueAmount: "$0.00",
    Status: "Completed",
    PaymentStatus: "Paid",
  },
];
