import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa6";

const Footer = () => {
  return (
    <div className="footer-section">
      <span>Terms & Conditions</span>
      <span>Copyright © 2024 BillTick</span>
      <div className="social">
        <FaInstagram className="social-icon" />
        <FaYoutube className="social-icon" />
        <FaFacebook className="social-icon" />
        <FaLinkedin className="social-icon" />
      </div>
    </div>
  );
};

export default Footer;
